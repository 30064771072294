import React, { useState, useEffect, useContext } from "react";
import maskot from "../../img/maskot.svg";
import { BackArrow } from "../../img/backarrow";
import { AppContext } from "../../core/state/context";
import closeBtn from "../../img/icons8-cancel@2x.png";
import filterWhen from "../../img/svgfilter_when.svg";
import filterWhere from "../../img/svgfilter_where.svg";
import filterExpedition from "../../img/svgexpedition.svg";
import { addTourMapObjects } from "../../core/map/maputils";

export const FilterWidget = ({ numFeatures }: any) => {
  let [activeWidget, setActiveWidget] = useState(false);
  let [showWidget, setShowWidget] = useState(activeWidget);
  let [showOverlay, setShowOverlay] = useState("");
  let context = useContext(AppContext);
  let additionalStyles = activeWidget
    ? "h-48 w-64 rounded-lg"
    : "h-16 w-16 rounded-full";

  //Delay to make animation pretty
  useEffect(() => {
    if (!activeWidget) {
      setShowWidget(activeWidget);
    } else {
      setTimeout(() => {
        setShowWidget(activeWidget);
      }, 500);
    }
  }, [activeWidget]);

  let getMainIcon = () => {
    if (context.mapFilter.value.includes("Tidshenvisning")) {
      return filterWhen;
    } else if (context.mapFilter.value.includes("Type")) {
      return filterWhere;
    } else if (context.mapFilter.value === "1=2") {
      return filterExpedition;
    } else {
      return maskot;
    }
  };

  let getHeadertext = () => {
    if (showOverlay.includes("Tidshenvisning")) {
      return "Hva skjedde når?";
    } else if (showOverlay.includes("Type")) {
      return "Hva finner jeg hvor?";
    } else {
      return "";
    }
  };

  return (
    <div>
      {showOverlay != "" && (
        <div className="absolute top-0 left-0 right-0 bottom-0 z-50 flex flex-col">
          <nav className="flex items-center justify-between flex-wrap headerColor">
            <div className="flex flex-row items-center flex-no-shrink text-white p-4 overflow-hidden">
              <button
                className="flex items-center justify-center pointer-cursor"
                onClick={() => {
                  setActiveWidget(false);
                  setShowOverlay("");
                }}
              >
                <BackArrow />
                <p className="ml-2">{getHeadertext()}</p>
              </button>
            </div>
          </nav>
          <div className="chalkboard-bg text-white h-full flex flex-col items-start p-2 pl-4">
            <button
              className="w-6 h-6 hover:bg-gray-700 rounded-full m-2 self-end flex items-center justify-center"
              onClick={() => {
                setShowOverlay("");
              }}
            >
              <img src={closeBtn} alt="" />
            </button>
            {!context.domainValues.value
              ? null
              : context.domainValues.value[showOverlay].codedValues.map(
                  (val: any) => (
                    <button
                      className="truncate overflow-hidden border-b border-white w-full p-2 text-left"
                      key={val.code}
                      onClick={() => {
                        context.mapFilter.set(
                          showOverlay + " = '" + val.code + "'"
                        );
                        setShowOverlay("");
                        setActiveWidget(false);
                      }}
                    >
                      {val.name}
                    </button>
                  )
                )}
          </div>
        </div>
      )}
      {(numFeatures === 0 && !context.selectedTour.value) && (
        <div
        className={
          "absolute right-0 bottom-0 m-4 z-20 bg-white shadow-lg transition-all duration-500 ease-in-out flex justify-center " +
          additionalStyles
        }
        >
        {!activeWidget && (
          <button
          className="w-full h-full flex items-center justify-center"
          onClick={() => {
            setActiveWidget(true);
          }}
          >
            <img className="h-12" src={getMainIcon()} alt="" />
          </button>
        )}
        {showWidget && (
          <div className="flex flex-col items-end justify-center p-2 pt-4 pb-4">
            <button
              className="p-1 flex items-center"
              onClick={() => {
                setShowOverlay("Tidshenvisning");
              }}
              >
              <p className="font-bold text-blue-800 pr-4">Hva skjedde når?</p>
              <img src={filterWhen} alt="" />
            </button>
            <button
              className="p-1 flex items-center"
              onClick={() => {
                setShowOverlay("Type");
              }}
              >
              <p className="font-bold text-blue-800 pr-4">
                Hva finner jeg hvor?
              </p>
              <img src={filterWhere} alt="" />
            </button>
            <button
              className="p-1 flex items-center"
              onClick={() => {
                setActiveWidget(false);
                context.mapFilter.set("1=2");
              }}
              >
              <p className="font-bold text-blue-800 pr-4">Vis ekspedisjoner</p>
              <img src={filterExpedition} alt="" />
            </button>
            <div className="h-1 w-full border-b border-gray-300 mt-1 mb-1"></div>
            <button
              onClick={() => {
                setActiveWidget(false);
                context.mapFilter.set("1=1");
              }}
              className="flex flex-row p-2 pb-4"
              >
              <p className="text-blue-800 pr-6">Vis alt </p>
              <p className="ml-2 block h-2 w-2 pr-4">X</p>
            </button>
          </div>
        )}
        </div>
      )}
    </div>
  );
};
