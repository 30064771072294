import React, { useContext } from "react";

import { AppContext } from "../core/state/context";
import { BackArrow } from "../img/backarrow";

export const IframePage = () => {
  const context = useContext(AppContext);
  const showIframeWindow = context.iframeURL.value !== null;
  return (
    <div>
      {showIframeWindow && (
        <div className="absolute top-0 left-0 right-0 bottom-0 z-50 flex flex-col">
          <nav className="flex items-center justify-between flex-wrap headerColor">
            <div className="flex flex-row items-center flex-no-shrink text-white p-4 overflow-hidden">
              <button
                className="flex items-center justify-center pointer-cursor"
                onClick={() => {
                  context.iframeURL.set(null);
                }}
              >
                <BackArrow />
                <p className="ml-2">Tilbake</p>
              </button>
            </div>
          </nav>
          <iframe src={context.iframeURL.value} className="flex-grow"></iframe>
        </div>
      )}
    </div>
  );
};
