import React, { useEffect, useState } from "react";
import { AppContext } from "../core/state/context";
import { useContext } from "react";
import { ExpeditionItemView } from "../components/Expedition/ExpeditionItemView";
import { getTours, getAllTours } from "../core/services/tourservice";
import { FilterView } from "../components/Filter/FilterView";
import { SortMode } from "../core/model/interfaces";

export const ToursPage = () => {
  const context = useContext(AppContext);

  const [tours, setTours] = useState(null);
  const [tourstops, setTourstops] = useState(null);
  // let tours: any;
  // let tourstops: any;
  const tourFilter = context.tourFilter.value; 
  const tourSort: SortMode = context.tourSort.value; 

  useEffect(() => {
    if(context.tours.value === null || tours === null) {      
      getAllTours(context).then((result: any) => {
        setTours(result.tours);
        setTourstops(result.tourstops);
      });
    }
  }, [context.tours.value]);

  const filterSortTours = (tours: any[]) => {
    // Filter tours
    let filteredTours: any[] = [];

    if (tourFilter !== null) {
      tours.map((tour: any) => {
        Object.entries(tourFilter).map(([key, values]: [string, any]) => {
          if (values.includes(tour.attributes[key]) && !filteredTours.includes(tour)) {
            filteredTours.push(tour);
          }
        }
        )});
    } else {
      filteredTours = [...tours];
    }

    // Sort tours
    let sortedTours: any[] = [];

    if (tourSort !== null) {
      sortedTours = filteredTours.sort((tourA: any, tourB: any) => {
        if (tourSort.attribute === 'position') {
          if (context.position.value) {
            // Get distances
          } else {
            // Get position, then get distances
          }
        } else {
          if (tourSort.direction === "asc") {
            return tourA.attributes[tourSort.attribute] - tourB.attributes[tourSort.attribute];
          } else {
            return tourB.attributes[tourSort.attribute] - tourA.attributes[tourSort.attribute];
          }
        }
      });
    } else {
      sortedTours = [...filteredTours];
    }

    return sortedTours;
  }
  
  const filteredTours = tours ? filterSortTours(tours) : null;

  return (    
    <div className="flex flex-col py-6 overflow-y-scroll">
      <FilterView toursLength={tours ? tours.length : null} filteredLength={tours ? filteredTours.length : null}/>
      {filteredTours && filteredTours.map((tour: any, index: number) => (
        <ExpeditionItemView key={index} expedition={tour}/>
      ))}
    </div>
  );
};
