import React, { useContext, useState, useEffect } from "react";
import { loadModules } from "esri-loader";
import { AppContext } from "../../core/state/context";
import { useHistory } from "react-router-dom";

import { ChalkboardFacts } from "../ChalkboardFacts/ChalkboardFactsView";
import maskot from "../../img/maskotChalk.svg";
import "./FeatureStyle.css";
import closeBtn from "../../img/icons8-cancel@2x.png";
import { getCodedValue } from "../../core/map/maplayerutils";
import { getAttachmentImage, getQuiz } from "../../core/services/tourservice";
import { Accordion } from "./Accordion";
import { ModalButton } from "./ModalButton";
import { QuizObject } from "../Quiz/QuizObject";
import { QuizView } from "../Quiz/QuizView";

export const HitMenu = ({ getTrip, features, expeditions }: any) => {
  const context = useContext(AppContext);
  const history = useHistory();

  return (
    <div className="flex flex-col absolute bottom-0 left-0 right-0 app-background p-2 z-30">
      <h6 className="text-white pl-2 font-bold">
        Treff i kartet ({features.length + expeditions ? expeditions.length : 0})
      </h6>
      {features.map((graphic: any, index: number) => {
        let { Navn, Type, OBJECTID } = graphic.attributes;
        let sourceLayer = graphic.sourceLayer || {
          id: "graphicslayer",
          fields: Object.keys(graphic.attributes).map((field) => {
            return {
              name: field,
              domain: { codedValues: [{ code: "1", name: field }] },
            };
          }),
        };

        if (graphic) {
          let topBorder = index === 0 ? "border-t" : "";
          return (
            <button
              className={
                "ml-1 mr-1 border-b border-white flex items-center justify-between pl-1 pr-1 " +
                topBorder
              }
              key={OBJECTID}
              onClick={() => context.selectedFeature.set(graphic)}>
              <p className="truncate text-xs text-white">
                {(Navn || "").trim() + (Type ?
                  ("  (" +
                  getCodedValue(sourceLayer, Type, "Type") +
                  ")") : "")}
              </p>
              <svg
                className="overflow-visible"
                viewBox="0 0 24 24"
                width="24"
                height="24"
                stroke="white"
                strokeWidth="2"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round">
                <polyline points="9 18 15 12 9 6"></polyline>
              </svg>
            </button>
          );
        }
      })}
      {(expeditions && expeditions.length > 0) ? expeditions.map((expedition: any, index: number) => {
        let { Navn, OBJECTID } = expedition.attributes;
        let topBorder = (index === 0 && features.length === 0) ? "border-t" : "";

        return (
          <button
              className={
                "ml-1 mr-1 border-b border-white flex items-center justify-between pl-1 pr-1 " + topBorder
              }
              key={OBJECTID}
              onClick={() => {
                // history.push('/tours', { prevPath: "/map" })
                // setTimeout(() => {
                  context.selectedTour.set(expedition);
                  getTrip();
                  // history.push('/map', { prevPath: "/tours" })
                // }, 1000);
              }
              }
              >
              <p className="truncate text-xs text-white">
                {`${Navn} (Ekspedisjon)`} 
              </p>
              <svg
                className="overflow-visible"
                viewBox="0 0 24 24"
                width="24"
                height="24"
                stroke="white"
                strokeWidth="2"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round">
                <polyline points="9 18 15 12 9 6"></polyline>
              </svg>
            </button>
        )
      })
        :
        null
    }
    </div>
  );
};

export const ImageFeature = ({}: any) => {
  const context = useContext(AppContext);
  const [featureImage, setFeatureImage] = useState(null);
  const [quiz, setQuiz] = useState(null);
  const [quizActive, setQuizActive] = useState(false);

  let {
    Navn,
    Fritekst,
    Storymap,
    URL,
    Intro,
    Kategori,
    Type,
    Tidshenvisning,
  } = context.selectedFeature.value.attributes;
  
  useEffect(() => {
    getQuiz(context.selectedFeature.value).then((result) => {
      setQuiz(result);
    })
  }, [context.selectedFeature.value]);

  useEffect(() => {
    if (context.qrReaderData.value && context.qrReaderData.value.includes(context.selectedFeature.value.attributes.GlobalID)) {
      setQuizActive(true);
    }
  }, [context.qrReaderData.value, context.selectedFeature.value]);
  
  getAttachmentImage(context.selectedFeature.value).then((result) => {
    setFeatureImage(result);
  });


  const sourceLayer = context.selectedFeature.value.sourceLayer;
  let featureType = !!Type ? getCodedValue(sourceLayer, Type, "Type") : "";
  let featureCategory = !!Kategori ? getCodedValue(sourceLayer, Kategori, "Kategori") : "";
  let featureTimeperiod = !!Tidshenvisning ? getCodedValue(
    sourceLayer,
    Tidshenvisning,
    "Tidshenvisning"
  ) : "";

  if (quizActive) {
    return <QuizView quiz={quiz} setQuizActive={setQuizActive}/>
  } else {
    return (
      <div className="absolute left-0 right-0 top-0 mt-12 z-20 bottom-0 app-background flex flex-col border-gray-800 border-2 z-30">
        <button
          className="w-6 h-6 bg-gray-500 hover:bg-gray-700 rounded-full m-2 absolute top-0 right-0 flex items-center justify-center"
          onClick={() => {
            context.selectedFeature.set(null);
            context.qrReaderData.set(null);
          }}
          >
          <img src={closeBtn} alt="" />
        </button>
        <div className="overflow-y-scroll hide-scrollbar flex flex-col items-center">
          <ChalkboardFacts text={Navn} question={false} />
          <p className="text-white p-2 font-semibold text-xs">{Intro}</p>
          {featureImage && (
            <img
            className="pictureHeight m-2"
            alt=""
            src={featureImage + "?token=" + context.token.value}
            />
            )}
          {quiz && (
            <Accordion
              longText={<QuizObject currentStop={context.selectedFeature.value}/>}
              caption=""
              name={"QUIZ"}
            />
          )}
          <Accordion
            longText={Fritekst}
            featureType={featureType}
            featureCategory={featureCategory}
            featureTimeperiod={featureTimeperiod}
            name={Navn}
            defaultActive={true}
            />
          {Storymap && (
            <ModalButton
            text={"Se karthistorie"}
            onClick={() => {
                context.iframeURL.set(Storymap);
              }}
              />
              )}
          {URL && (
            <ModalButton
            text={"Ekstern lenke"}
            onClick={() => {
              context.iframeURL.set(URL);
            }}
            />
            )}
        </div>
      </div>
    );
  }
};

export const GraphicsCliked = ({ getTrip, features, expeditions }: any) => {
  const context = useContext(AppContext);
  if (context.selectedFeature.value) {
    return <ImageFeature />;
  } else if (features.length > 0 || (expeditions && expeditions.length > 0)) {
    return <HitMenu getTrip={getTrip} features={features} expeditions={expeditions} />;
  } else {
    return null;
  }
};
