import React, { useContext, useState, useEffect } from "react";
import { AppContext } from "../../core/state/context";
import QrReader from 'react-qr-reader'
import "./QuizStyle.css";
import { ChalkboardFacts } from "../ChalkboardFacts/ChalkboardFactsView";
import svgradioInactive from "../../assets/svgradiobutton_inactive.svg";
import svgradioActive from "../../assets/svgradiobutton_active.svg";
import { saveMovement } from "../../core/services/tourservice";

export const QuizView = ({quiz, setQuizActive}: any) => {
    const context = useContext(AppContext);
    const [selectedOption, setSelectedOption] = useState(null);
    const [submittedAnswer, setSubmittedAnswer] = useState(null);

    useEffect(() => {
      if (submittedAnswer) {
        saveMovement(context, quiz, submittedAnswer).then(() => {
          console.log("Quiz sendt inn.");
        });
      }
    }, [context, submittedAnswer, quiz]);
    
    if (!quiz) {
      return null;
    }
    
    const {
      Alt1,
      Alt2,
      Alt3,
      Alt4,
      Riktig,
      Spørsmål 
    } = quiz.attributes;

    return (
      <div className="absolute left-0 right-0 top-0 mt-12 z-20 bottom-0 app-background flex flex-col border-gray-800 border-2 z-30">
      <div className="overflow-y-scroll hide-scrollbar flex flex-col items-center">
        <ChalkboardFacts text="QUIZ" question={true} />
        <div className="p-4 text-center text-white">
          {!submittedAnswer ? Spørsmål : submittedAnswer === Riktig ? 
            `Gratulerer, det er helt rett! Du fikk akkurat ${5} poeng!`
            :
          `Dessverre feil svar. Riktig svar er ${quiz.attributes["Alt" + Riktig]}`
          }
        </div>


        {!submittedAnswer && (
          <div className="w-full">
            <div onClick={() => setSelectedOption(1)} className="quiz-question p-3 mb-2">
              <div className="flex items-center">
                <img className="px-2" src={selectedOption === 1 ? svgradioActive : svgradioInactive}/>
                {Alt1}
              </div>
            </div>
            <div onClick={() => setSelectedOption(2)} className="quiz-question p-3 mb-2">
            <div className="flex items-center">
              <img className="px-2" src={selectedOption === 2 ? svgradioActive : svgradioInactive}/>
                {Alt2}
              </div>
            </div>
            <div onClick={() => setSelectedOption(3)} className="quiz-question p-3 mb-2">
              <div className="flex items-center">
                <img className="px-2" src={selectedOption === 3 ? svgradioActive : svgradioInactive}/>
                {Alt3}
              </div>
            </div>
            <div onClick={() => setSelectedOption(4)} className="quiz-question p-3 mb-2">
              <div className="flex items-center">
                <img className="px-2" src={selectedOption === 4 ? svgradioActive : svgradioInactive}/>
                {Alt4}
              </div>
            </div>
          </div>  
        )}
        <div className="text-center flex flex-col">
          {!submittedAnswer && (
            <button onClick={() => setSubmittedAnswer(selectedOption)} className="border-4 quiz-submit rounded-lg py-3 px-5 m-4">
              Avgi svar
            </button>
          )}
          <button className="quiz-cancel border-4 rounded-lg text-sm p-2 m-1" onClick={() => {
            setQuizActive(false);
            context.qrReaderData.set(null);
          }}>
            Avslutt Quiz
          </button>
        </div>
      </div>
    </div>
    );
  };