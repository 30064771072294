import React from "react";
import { WebMapView } from "../components/WebMap/WebMapView";
import { RouteComponentProps } from "react-router-dom";

export const MapPage = ({ match }: RouteComponentProps<any>) => {
  return (
    <div className="flex flex-grow overflow-hidden">
      <WebMapView match={match}/>
    </div>
  );
};
