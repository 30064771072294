import React from "react";
import { useHistory } from "react-router-dom";
import {IExpeditionProps, IExpedition} from "../../core/model/interfaces";
import skattekart from "../../img/skattekart.png";
import { AppContext } from "../../core/state/context";
import { useContext } from "react";
import { getCodedValue } from "../../core/map/maplayerutils";
import Ruler from "../../assets/svgicons8-ruler.svg";
import Flag from "../../assets/svgicons8-flag_2.svg";
import StopWatch from "../../assets/svgicons8-timer.svg";
import Rating from "../../assets/svgicons8-rating.svg";
import SuitedFor from "../../assets/svgicons8-children.svg";
import Tech from "../../assets/svgicons8-assistive_technology.svg";
import PointRight from "../../assets/marker-right.svg";
import { numberOfPosts } from "../../core/services/tourservice";
 
export const ExpeditionItemHomePageView = (props: IExpeditionProps) => {
  const context = useContext(AppContext);
  const history = useHistory();
  
  const openTour = (expedition: IExpedition) => {
    context.selectedTour.set(expedition);
    history.push('/map', { prevPath: "/home" })
  }

  const tryGetMapImage = (expedition: IExpedition) => {
    if(expedition.attributes.attachmentUris && expedition.attributes.attachmentUris.length > 0) {
      console.log(expedition.attributes.attachmentUris[0]);
      return expedition.attributes.attachmentUris[0];
    }
    return skattekart;
  }

  let expedition = props.expedition;

  return (
    <>
      <div className="new-expedition-container flex relative">
        <div className="new-expedition-content-container flex w-3/5 absolute right-0 rounded-l-full px-4 py-1">
          UKENS EKSPEDISJON
        </div>
      </div>
      <div className="text-white app-container-bg py-4 rounded-lg mt-4">        
        
          <div className="flex items-stretch p-2 h-28" onClick={() => openTour(expedition)}>
            <div className="h-44 w-1/2">
            <img
              className="expedition-thumbnail"
              alt="Skattekart"
              src={tryGetMapImage(expedition)}
            />
            </div>
            
            <div className="pl-2 w-1/2">
            <h4 className="text-lg">{expedition.attributes.Navn}</h4>        
            {/*<h6>{getCodedValue(expedition.sourceLayer, expedition.attributes.Type, "Type")}</h6>*/}
            <div className="flex">
            <div className="w-1/2 py-1">
                <div className="text-sm">                  
                    {(expedition.attributes.Shape__Length/1000).toFixed(1)} km                 
                </div>   
                <div className="dim-text">LENGDE</div>
              </div>              
              <div className="w-1/2 py-1">
                <div className="text-sm">
                    {expedition.attributes.Varighet && expedition.attributes.Varighet.toFixed(1) || "Ukjent"} timer
                </div>
                <div className="dim-text">TID</div>                                 
              </div>
            </div>

            <div className="flex">
              <div className="w-1/2 py-1">
                <div>
                {numberOfPosts(expedition, context)}
                </div>
                <div className="dim-text">POSTER</div>                                 
              </div>
              <div className="w-1/2 py-1">
                <div className="text-sm">                  
                  {`${expedition.attributes.Poeng}`}
                </div>   
                <div className="dim-text">POENG</div>
              </div>
            </div>       
            <div className="flex">
              <div className="w-full pt-2">
                <div className="flex text-sm">
                    <img src={SuitedFor} className="w-4 mr-2"/>                    
                    {getCodedValue(expedition.sourceLayer, expedition.attributes.Passer_for, "Passer_for") || "Informasjon mangler"}
                </div>
                <div>
                </div>
              </div>               
            </div>
            </div> 
            <div className="flex items-center mr-1">
            <img src={PointRight} className="w-2"/>
          </div>           
          </div>

          <div className="p-2 text-sm">{expedition.attributes.Intro}</div>
      </div>
      </>
  );
};
