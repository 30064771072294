import React from "react";
import { RouteComponentProps } from "react-router-dom";

export const TourPage = ({ match }: RouteComponentProps<any>) => {  
  let tourid = match.params.tourid;
  return (
    <div>
      <p>Tour id: {tourid}</p>
    </div>
  );
};
