import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { AppContext } from "../../core/state/context";
import { useContext } from "react";
import { queryPortalItem } from "../../core/services/portalitemservice";
import "./RegistrationPageStyles.css";
import welcomeMascot from "../../img/welcome.svg";

export const RegistrationPage = () => {
  const history = useHistory();
  const context = useContext(AppContext);
  const [agentValidate, setAgentValidate] = useState("");
  const validateAgent = () => {
    var query = {
      where: `kode = '${agentValidate}'`,
      token: context.token.value,
      f: "json",
      outFields: "*"
    };
    queryPortalItem(query).then((response: any) => {
      if (response.data.features.length === 1) {
        let feature = response.data.features[0];
        context.agent.set(feature.attributes["Kode"]);
        history.push("/home");
      } else {
        alert("Fant ingen agent med angitt ID");
      }
    });
  };

  const onUpdateAgentName = (e: React.FormEvent<HTMLInputElement>) => {
    setAgentValidate(e.currentTarget.value);
  };

  const continueWithNoAgent = () => {
    history.push("/home");
  };

  return (
    <div className="flex flex-col items-center justify-center p-6">
      <div className="flex items-center justify-center">
        <img src={welcomeMascot} alt="" />
        <div className="flex flex-col p-6">
          <h2 className="text-xl font-sans mb-2 text-color">Velkommen!</h2>
          <h2 className="font-bold text-color">Bli med på</h2>
          <h1 className="font-bold text-3xl -mt-2 text-color">ekspedisjon!</h1>
        </div>
      </div>
      <div className="flex flex-col items-center mt-8 w-64 h-screen">
        <h2 className="mb-2 text-color">Har du en geo-agentkode?</h2>
        <input
          placeholder="Tast inn din agentkode"
          className="bg-white focus:outline-none focus:shadow-outline border border-gray-300 rounded-full py-2 px-4 block w-full appearance-none leading-normal text-center"
          onChange={onUpdateAgentName}
          value={agentValidate}
        />
        <button
          onClick={validateAgent}
          className="login-btn bg-blue-500 hover:bg-blue-700 text-white py-2 px-4 rounded-full mt-6 w-full shadow-xl border-2"
        >
          Logg inn
        </button>
        <button
          onClick={continueWithNoAgent}
          className="continue-btn bg-blue-500 hover:bg-blue-700 text-white py-2 px-4 rounded-full mt-32 w-full border-2"
        >
          Fortsett uten kode...
        </button>
      </div>
    </div>
  );
};
