import { useState, useEffect } from "react";
import React from "react";

export const Accordion = ({
    longText,
    featureType,
    featureCategory,
    featureTimeperiod,
    name,
    caption = "Lær mer om",
    defaultActive = false

  }: any) => {
    const [active, setActive] = useState(false);

    useEffect(() => {
      if(defaultActive) {
        setActive(defaultActive);
      }
    },
    [defaultActive])
    
    return (
      <div className="mb-1">
        <button
          className="w-full h-10 accordion-bg text-left pl-4 pr-2 flex justify-between items-center "
          onClick={() => setActive(!active)}
        >
          <p className="text-white truncate text-sm font-bold" dangerouslySetInnerHTML={{__html: caption + ' ' + name }}>
          </p>
          <svg
            className={`transform transition-all duration-500 ease-in-out ${
              active ? "-rotate-90" : "rotate-90"
            }`}
            viewBox="0 0 24 24"
            width="24"
            height="24"
            stroke="white"
            strokeWidth="2"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <polyline points="9 18 15 12 9 6"></polyline>
          </svg>
        </button>
        <div
          className={`transform transition-all duration-500 ease-in-out overflow-hidden accordion-animation accordion-bg pl-4 pr-4 ${
            active ? "accordion-animation-after" : ""
          }`}
        >
          <p className="pt-4 text-white text-xs font-bold">{featureCategory}</p>
          <p className=" text-white text-s font-semibold">{featureType}</p>
          <p className="text-white text-xs pb-2">{featureTimeperiod}</p>
          <span className="text-white text-xs">{longText}</span>
        </div>
      </div>
    );
  };