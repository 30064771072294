import React from "react";

export const BackArrow = () => {
  return (
    <svg
      className="fill-current text-gray-900 iconColor block"
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
    >
      <g
        id="Webapp"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fill-rule="evenodd"
      >
        <g
          id="Visste-du"
          transform="translate(-13.000000, -37.000000)"
          fill="#DCBA52"
          fill-rule="nonzero"
        >
          <g id="menubar" transform="translate(0.000000, 20.000000)">
            <g
              id="back"
              transform="translate(22.777778, 25.222222) rotate(90.000000) translate(-22.777778, -25.222222) translate(14.777778, 15.222222)"
            >
              <path
                d="M7.77777778,0 C7.16444444,0 6.66666667,0.497777778 6.66666667,1.11111111 L6.66666667,15.63368 L2.374132,11.3411456 C1.93968744,10.9067011 1.23628478,10.9067011 0.802951333,11.3411456 L0.707465333,11.4366322 C0.273020778,11.8710767 0.273020778,12.5744789 0.707465333,13.0078122 L6.99218778,19.2925344 C7.42663222,19.7269789 8.13003444,19.7269789 8.56336778,19.2925344 L14.84809,13.0078122 C15.2825344,12.5733678 15.2825344,11.8699656 14.84809,11.4366322 L14.7526044,11.3411456 C14.31816,10.9067011 13.6147567,10.9067011 13.1814233,11.3411456 L8.88888889,15.63368 L8.88888889,1.11111111 C8.88888889,0.497777778 8.39111111,0 7.77777778,0 Z"
                id="Path"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
