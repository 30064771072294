import axios from "axios";
import qs from 'querystring';
import { appSettings } from "../settings";
import { IExpedition } from "../model/interfaces";
import { loadModules } from "esri-loader";

const config = {
    headers: {
      'Content-Type': 'application/json'
    }
}

export const getTours = (query: any): Promise<any> => {   
    let qparams = query;    
    return new Promise((resolve, reject) => {
        axios.all([axios.get(`${appSettings.serviceBaseUrl}/${appSettings.geoTripsServicePath}/query?${qs.stringify(qparams)}`, config),
        axios.get(`${appSettings.serviceBaseUrl}/${appSettings.geoTripStopsServicePath}/query?${qs.stringify(qparams)}`, config)])
        .then((results) => {
          if(results[0].data.error && results[0].data.error.code >= 400) {
            reject(results[0].data.error.code);
            return;
          }
        
          let objectids = results[0].data.features.map((expedition: IExpedition) => expedition.attributes.OBJECTID).join(",");
          let attqp = Object.assign({}, { token: qparams.token, f: qparams.f })
          axios.get(`${appSettings.serviceBaseUrl}/${appSettings.geoTripsServicePath}/queryAttachments?objectids=${objectids}&${qs.stringify(attqp)}`, config).then((attatchemts: any) => {
              let atts = attatchemts.data.attachmentGroups.map((attachment: any) => {
                  let feature = results[0].data.features.filter((expedition: IExpedition) => {
                      return expedition.attributes.OBJECTID === attachment.parentObjectId;
                  })[0];
                  feature.attributes.attachmentUris = attachment.attachmentInfos.filter((attinfo: any) => ["image/jpeg", "image/png"].indexOf(attinfo.contentType.toLowerCase()) > -1).map((attinfo: any) => {
                      return `${appSettings.serviceBaseUrl}/${appSettings.geoTripsServicePath}/${feature.attributes.OBJECTID}/attachments/${attinfo.id}?token=${qparams.token}`;
                  });
                  feature.attributes.attachmentFiles = attachment.attachmentInfos.filter((attinfo: any) => ["application/pdf"].indexOf(attinfo.contentType.toLowerCase()) > -1).map((attinfo: any) => {
                    return `${appSettings.serviceBaseUrl}/${appSettings.geoTripsServicePath}/${feature.attributes.OBJECTID}/attachments/${attinfo.id}?token=${qparams.token}`;
                  });
              })
              resolve(results);
          })
            
        });
    });
}

export const getAllTours = (context: any): Promise<any> => {

  if(context.tours.value && context.tourstops.value) {
    return new Promise((resolve) => {
      resolve({ tours: context.tours.value, tourstops: context.tourstops })
    })
  }

  let query = {
    where: "1=1", // give us all tours
    outFields: "*",
    token: context.token.value,
    f: "json"
  };
  return new Promise((resolve, reject) => {

  getTours(query).then((response) => {         
    let tours = response[0].data.features.map((tour: any) => {
      Object.assign(tour, { sourceLayer: {
        fields: response[0].data.fields,
        url: response[0].config.url.substring(0, response[0].config.url.match(/\/(\d+)\//).index+1),
        layerId: response[0].config.url.match(/\/(\d+)\//)[1]
      }})      
      return tour;
    });
    let tourstops = response[1].data.features.map((tourstop: any) => {
      Object.assign(tourstop, { sourceLayer: {
        fields: response[1].data.fields,
        url: response[1].config.url.substring(0, response[1].config.url.match(/\/(\d+)\//).index+1),
        layerId: response[1].config.url.match(/\/(\d+)\//)[1]
      }})
      return tourstop;
    });
    context.tours.set(tours);
    context.tourstops.set(tourstops);
      resolve({tours, tourstops });
    })
  })
}

const getTourObjects = (query: any): Promise<any> => {
    let qparams = query;
    return new Promise(resolve => {
        axios.all([axios.get(`${appSettings.serviceBaseUrl}/${appSettings.objectPoints}/query?${qs.stringify(qparams)}`, config)])
            .then((results) => {
                resolve(results);
            })
    })
}

export const getAllTourObjects = (context: any, tourGlobalIDs: (string | null)[] = null, includeObjects: boolean = false): Promise<any> => {
    if(!includeObjects) {
        return new Promise(resolve => {
            resolve(null);
        })
    }
    let hasObjectFilter = (ts: any) => !!ts.attributes.Objekt_ID
    let stops = context.tourstops.value;
    if(tourGlobalIDs !== null) {
        stops = context.tourstops.value.filter((ts: any) => ts.attributes.Tur && tourGlobalIDs.indexOf(ts.attributes.Tur) > -1 && !!ts.attributes.Objekt_ID);
    }

    if(stops.length > 0) {
      let query = {
        where: `GlobalID IN ('${stops.filter(hasObjectFilter).map((ts: any) => ts.attributes.Objekt_ID.toLowerCase()).join('\',\'')}')`,
        outFields: "*",
        token: context.token.value,
        f: "json"
      }
      return new Promise(resolve => {
        getTourObjects(query).then((results) => {
          resolve(results);
        })
      })      
    }
    return new Promise(resolve => {
        resolve(null);
    })
}

export const numberOfPosts = (expedition: IExpedition, context: any) => {
    let tripStops = context.tourstops.value;
    if(tripStops) {
      return tripStops.filter((f: any) => {
        return f.attributes.Tur && expedition.attributes.Navn && (f.attributes.Tur.toLowerCase() === expedition.attributes.Navn.toLowerCase());
      }).length;
    }
    return 0;
  }

export const startTrip = (expedition: IExpedition, context: any) => {
    // are we storing the active trip in sessionStorage?
    // if that is the case, it is lost if user close the browser... bad idea?
    // should be stored in localStorage - user must manually cancel it
    localStorage.setItem("active-expedition", expedition.attributes.GlobalID);
    context.selectedTour.set(null);
    context.inProgress.set({ trip: expedition });

    let firstStop = context.tourstops.value.find((stop: any) => stop.attributes.Tur === expedition.attributes.Navn && stop.attributes["Type_stopp"] === "Startpunkt");
    if(firstStop !== null) {
      setCurrentStop(firstStop, context);
    }
}

export const cancelCurrentTrip = (context: any) => {
  // are we storing the active trip in sessionStorage?
  // if that is the case, it is lost if user close the browser... bad idea?
  // should be stored in localStorage - user must manually cancel it
  localStorage.setItem("active-expedition", null);
  context.selectedTour.set(null);
  context.inProgress.set(null);
  context.selectedFeature.set(null);
}

export const getCurrentStop = (expedition: IExpedition, context: any) => {
  if(context.activeStop.value !== null) {
    return context.activeStop.value;
  }

  let currStop = localStorage.getItem("current-tour-stop");
  if(currStop) {
    let stops = context.tourstops.value.filter((ts: any) => ts.attributes.Tur === expedition.attributes.Navn).filter((ts: any) => ts.attributes.GlobalID === currStop);
    return stops && stops.length === 1 ? stops[0] : null;
  }
  return null;
}

export const setCurrentStop = (stop: any, context: any) => {
  localStorage.setItem("current-tour-stop", stop.attributes.GlobalID);
  context.activeStop.set(stop);
}

export const leaveCurrentStop = (context: any) => {
  localStorage.removeItem("current-tour-stop");
  context.activeStop.set(null);
}

export const getActiveTrip = (context: any) => {
  return new Promise((resolve, reject) => {
    let active = localStorage.getItem("active-expedition");
    if(typeof(active) !== "undefined") {
      if(!context.tours.value && !context.tourstops.value) {
        getAllTours(context).then(({tours, tourstops}) => {
          resolve(getActiveTour(tours, active, context));
        })
      } else {
        resolve(getActiveTour(context.tours.value, active, context));
      }        
    } else {
      reject(null);
    }
  });
}

export const getAttachmentImage = (feature: any) => {
    return new Promise((resolve, reject) => {
      loadModules([
        "esri/tasks/QueryTask",
        "esri/tasks/support/AttachmentQuery",
      ]).then(([QueryTask, AttachmentQuery]) => {
        const attachmentQuery = new AttachmentQuery({
          attachmentTypes: ["image/jpeg", "image/png"],
          where: "1=1",
          objectIds: [feature.attributes.OBJECTID],
        });
        const queryTask = new QueryTask({
          url: feature.sourceLayer.url + "/" + feature.sourceLayer.layerId,
        });
        queryTask
          .executeAttachmentQuery(attachmentQuery)
          .then((result: any) => {
            if(result[feature.attributes.OBJECTID]) {
              let attachments = result[feature.attributes.OBJECTID];
              let image = attachments[0];
              let imageURL = image.url;
              resolve(imageURL);
            } else {
              resolve("");
            }
          });
      });
    });
  };

  export const getQuiz = (feature: any) => {
    return new Promise((resolve, reject) => {
      loadModules([
        "esri/tasks/QueryTask",
        "esri/tasks/support/RelationshipQuery",
      ]).then(([QueryTask, RelationshipQuery]) => {
        const relationshipQuery = new RelationshipQuery({
          relationshipId: 0,
          where: "1=1",
          outFields: ["*"],
          objectIds: [feature.attributes.OBJECTID],
        });
        const queryTask = new QueryTask({
          url: feature.sourceLayer.url + "/" + feature.sourceLayer.layerId,
        });
        queryTask
          .executeRelationshipQuery(relationshipQuery)
          .then((result: any) => {
            if(result[feature.attributes.OBJECTID]) {
              const quizQuestions = result[feature.attributes.OBJECTID].features;
              resolve(quizQuestions[Math.floor(Math.random() * (quizQuestions.length))]);
            } else {
              resolve("");
            }
          });
      });
    });
  };

  export const saveMovement = (context: any, quiz?: any, answer?: any) => {
    return new Promise((resolve, reject) => {
      loadModules([
        "esri/layers/FeatureLayer",
        "esri/Graphic"
      ]).then(([FeatureLayer, Graphic]) => {
        const movementLayer = new FeatureLayer(appSettings.serviceBaseUrl + appSettings.movementsTable);

        const newFeature: any = {
          attributes: {
            Turstopp_ID: context.activeStop.value ? context.activeStop.value.attributes.OBJECTID : 0,
            Tur_ID: context.inProgress.value ? context.inProgress.value.trip.attributes.OBJECTID : 0,
            Tur_Navn: context.inProgress.value ? context.inProgress.value.trip.attributes.Navn : 0,
            Type_turstopp: context.activeStop.value ? context.activeStop.value.attributes.Type_stopp : 0,
            Agentkode: context.agent.value ? context.agent.value : "0",
            Spørsmål_ID: quiz ? quiz.attributes.Objekt_ID : null,
            Svaralternativ: answer ? answer : null,
            Riktig_Feil: quiz ? quiz.attributes.Riktig === answer ? "Riktig" : "Feil" : null,
            Poeng_Quiz: quiz ? quiz.attributes.Riktig === answer ? 5 : 0 : null,
            Poeng_Destinasjon: context.activeStop.value ? context.activeStop.value.attributes.Type_stopp === "Destinasjon og Quiz" ? context.inProgress.value.trip.attributes.Poeng : 0 : 0
          }
        }
        const graphic = new Graphic(newFeature); 
      
        movementLayer.load().then(() => {
          movementLayer.applyEdits({addFeatures: [graphic]}).then(() => {
            resolve();
          })
        })
      });
    });
  };

  const getActiveTour = (tours: any, active: string, context: any) => {
    let activeTour = tours.filter((tour: IExpedition) => tour.attributes.GlobalID === active);
        if(activeTour.length > 0) {
          let theActive = activeTour[0];
          let completedStops = localStorage.getItem(`${theActive.attributes.GlobalID}-stops`);          
          context.inProgress.set({ trip: theActive, completedStops });
          return theActive;
        }
  }