export const getCodedValue = (
    sourceLayer: any,
    codedValueNumber: number,
    attributeKey: string
  ) => {
    let field = sourceLayer.fields.find(
      (afield: any) => afield.name === attributeKey
    );
    let domain = field.domain;
    let codedValue = domain.codedValues.find(
      (codedValue: any) => codedValue.code == codedValueNumber
    );
    return codedValue && codedValue.name || "";
  };