import React from "react";

type ModalButtonProps = {
    text: string;
    onClick: (event: any) => void;
};

export const ModalButton = ({ text, onClick }: ModalButtonProps) => {
    return (
      <button
        className="w-full h-10 accordion-bg accordion-layout text-left pl-4 pr-2 flex justify-between items-center mt-1 mb-1"
        onClick={onClick}
      >
        <p className="text-white truncate text-sm font-bold">{text}</p>
        <svg
          viewBox="0 0 24 24"
          width="24"
          height="24"
          stroke="white"
          strokeWidth="2"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <polyline points="9 18 15 12 9 6"></polyline>
        </svg>
      </button>
    );
  };