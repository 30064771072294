import React, { useEffect, useContext, useState } from "react";
import { useSwipeable } from "react-swipeable";
import "./ChalkboardStyle.css";
import maskotQuestion from "../../img/maskotQuestion.svg";
import maskot from "../../img/maskotChalk.svg";
import navleft from "../../img/svgnav_left.svg";
import navright from "../../img/svgnav_right.svg";
import "../../fonts/Chalkduster.ttf";
import { loadModules } from "esri-loader";
import { AppContext } from "../../core/state/context";

type ChalkboardProps = {
  text?: string;
  question?: boolean;
};

export const ChalkboardFacts = ({ text, question }: ChalkboardProps) => {
  const context = useContext(AppContext);
  const [funFacts, setFunFacts] = useState([]);
  const [funFactIndex, setFunFactIndex] = useState(null);

  let getRandomFunfacts = (funFacts: Array<string>): Array<string> => {
    let newFunfacts = [];
    const numberOfFunfacts = 5;
    if (funFacts.length <= numberOfFunfacts) {
      return funFacts.sort(function () {
        return 0.5 - Math.random();
      });
    }
    for (let index = 0; index < numberOfFunfacts; index++) {
      let randomIndex = Math.floor(Math.random() * funFacts.length);
      newFunfacts.push(funFacts.splice(randomIndex, 1)[0]);
    }
    return newFunfacts;
  };

  useEffect(() => {
    if (funFacts.length === 0 && !text) {
      let servicesURL =
        "https://services9.arcgis.com/8Nu0wdjLqwKHS0Kd/arcgis/rest/services/Objekt_punkter/FeatureServer/0";

      servicesURL = servicesURL + "?token=" + context.token.value;
      loadModules(["esri/tasks/QueryTask", "esri/tasks/support/Query"]).then(
        ([QueryTask, Query]) => {
          var queryTask = new QueryTask({
            url: servicesURL,
          });
          var query = new Query();
          query.returnGeometry = false;
          query.outFields = ["Intro"];
          query.where = "Fun_fact  = 1 AND Intro is not null"; // True/Ja er lik 1

          queryTask.execute(query).then(function (results: any) {
            setFunFactIndex(0);
            let funFacts = results.features.map(
              (feature: any) => feature.attributes.Intro
            );
            setFunFacts(getRandomFunfacts(funFacts));
          });
        }
      );
    }
  });

  const handleSwipe = (direction: string) => {
    if (direction === "left") {
      setFunFactIndex((funFactIndex + 1) % funFacts.length);
    } else if (direction === "right") {
      setFunFactIndex((funFactIndex + funFacts.length - 1) % funFacts.length);
    }
  };

  const handlers = useSwipeable({
    onSwipedLeft: () => handleSwipe("left"),
    onSwipedRight: () => handleSwipe("right"),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  return (
    <div>
      <div className="flex flex-row p-4 text-white justify-center items-center">
        <img
          className="-mr-4 z-10"
          src={question ? maskotQuestion : maskot}
          alt="Maskotten spør"
        />
        <div
          className="chalkboard-fill p-6 m-2 rounded-lg chalkboard-border border-8 flex items-center"
          {...handlers}
        >
          <p className="text-l chalkboard-text">
            {funFacts.length > 0 ? funFacts[funFactIndex] : text}
          </p>
        </div>
      </div>
      {funFacts.length > 0 && (
        <div className="flex flex-row items-center justify-center">
          <button
            className="mr-4"
            onClick={() => {
              handleSwipe("right");
            }}
          >
            <img src={navleft} alt="" />
          </button>
          <div className="flex flex-row items-center justify-center">
            {funFacts.map((funFact, i) => {
              return i === funFactIndex ? (
                <span
                  key={i}
                  className="h-2 w-2 dot-bg rounded-full m-1"
                ></span>
              ) : (
                <span key={i} className="h-2 w-2 bg-gray-200 rounded-full m-1"></span>
              );
            })}
          </div>
          <button
            className="ml-4"
            onClick={() => {
              handleSwipe("left");
            }}
          >
            <img src={navright} alt="" />
          </button>
        </div>
      )}
    </div>
  );
};
