import React, { useEffect } from "react";
import { ChalkboardFacts } from "../components/ChalkboardFacts/ChalkboardFactsView";
import { ExpeditionView } from "../components/Expedition/ExpeditionView";
import { ExplorerFooter } from "../components/ExplorerFooter/ExplorerFooterView";
import { LoadingAnimation } from "../components/LoadingAnimation/LoadingAnimation";
import { AppContext } from "../core/state/context";
import { useContext } from "react";
import { getTours } from "../core/services/tourservice";
import { IExpedition } from "../core/model/interfaces";


export const HomePage = () => {
  const context = useContext(AppContext);
  context.selectedTour.value = null;
  let tours = context.tours.value;
  let tourstops = context.tourstops.value;  
  
  const getHomePageTour = () => {
    if(tours && tours[0])
      return tours.filter((tour: IExpedition) => tour.attributes.Startside === "Ja").concat(tours[0])[0];
    return null;
  }

  let homepageTour = getHomePageTour();

  useEffect(() => {
    if (tours === null) {
      let query = {
        where: "1=1", // give us all tours
        outFields: "*",
        token: context.token.value,
        f: "json"
      };
      if(!context.tours.value) {
        getTours(query).then(response => {
          tours = response[0].data.features.map((tour: any) => {
            Object.assign(tour, { sourceLayer: {
              fields: response[0].data.fields,
              url: response[0].config.url.substring(0, response[0].config.url.match(/\/(\d+)\//).index+1),
              layerId: response[0].config.url.match(/\/(\d+)\//)[1]
            }})      
            return tour;
          });
          tourstops = response[1].data.features.map((tourstop: any) => {
            Object.assign(tourstop, { sourceLayer: {
              fields: response[1].data.fields,
              url: response[1].config.url.substring(0, response[1].config.url.match(/\/(\d+)\//).index+1),
              layerId: response[1].config.url.match(/\/(\d+)\//)[1]
            }})
            return tourstop;
          });
          context.tours.set(tours);
          context.tourstops.set(tourstops);        
        });
      }
    }
  }, [context.tours.value]);

  return (
    (tours && (
      <div className="overflow-y-scroll h-full">
        <ChalkboardFacts question={true} />
        <ExpeditionView expedition={homepageTour} />
        <ExplorerFooter />
      </div>
    )) || <LoadingAnimation />
  );
};
