import React from "react";
import { useHistory } from "react-router-dom";
import {IExpeditionProps, IExpedition} from "../../core/model/interfaces";
import skattekart from "../../img/skattekart.png";
import { AppContext } from "../../core/state/context";
import { useContext } from "react";
import { getCodedValue } from "../../core/map/maplayerutils";
import Ruler from "../../assets/svgicons8-ruler.svg";
import Flag from "../../assets/svgicons8-flag_2.svg";
import StopWatch from "../../assets/svgicons8-timer.svg";
import Rating from "../../assets/svgicons8-rating.svg";
import SuitedFor from "../../assets/svgicons8-children.svg";
import Tech from "../../assets/svgicons8-assistive_technology.svg";
import PointRight from "../../assets/marker-right.svg"
import { numberOfPosts } from "../../core/services/tourservice";
 
export const ExpeditionItemView = (props: IExpeditionProps) => {
  const context = useContext(AppContext);
  const history = useHistory();
  
  const openTour = (expedition: IExpedition) => {
    context.selectedTour.set(expedition);
    history.push('/map', { prevPath: history.location.pathname })
  }

  const tryGetMapImage = (expedition: IExpedition) => {
    if(expedition.attributes.attachmentUris && expedition.attributes.attachmentUris.length > 0) {
      return expedition.attributes.attachmentUris[0];
    }
    return skattekart;
  }

  let expedition = props.expedition;

  return (
      <div className="text-white app-container-bg p-4 mt-4">
        <div>
        <h4 className="text-xl">{expedition.attributes.Navn}</h4>        
        <h6>{getCodedValue(expedition.sourceLayer, expedition.attributes.Type, "Type")}</h6>
          <div className="flex items-stretch py-2 h-28" onClick={() => openTour(expedition)}>
            
          <div className="h-44 w-1/2">
            <img
              className="expedition-thumbnail"
              alt="Skattekart"
              src={tryGetMapImage(expedition)}
            />
            </div>
            
            <div className="pl-4 w-2/5">              
              <div className="flex py-1">
                <div>
                  <div className="w-8 p-1">
                    <img src={Ruler} className="w-4"/>                    
                  </div>                                 
                </div>
                <div>
                <div className="w-20 label-text">
                  <p>{(expedition.attributes.Shape__Length/1000).toFixed(1)} km</p>
                  </div>   
                </div>
              </div>
              {numberOfPosts(expedition, context) > 0 &&
              <div className="flex py-1">
                <div>
                  <div className="w-8 p-1">
                    <img src={Flag} className="w-4"/>                    
                  </div>                                 
                </div>
                <div>
                <div className="w-20 label-text">
                  <p>{numberOfPosts(expedition, context)} poster</p>
                  </div>   
                </div>
              </div>
              }
              <div className="flex py-1">
                <div>
                  <div className="w-8 p-1">
                    <img src={StopWatch} className="w-4"/>                    
                  </div>                                 
                </div>
                <div>
                <div className="w-20 label-text">
                  <p>{expedition.attributes.Varighet && expedition.attributes.Varighet.toFixed(1) || "Ukjent"} timer</p>
                  </div>   
                </div>
              </div>
              
              <div className="flex py-1">
                <div>
                  <div className="w-8 p-1">
                    <img src={Rating} className="w-4"/>                    
                  </div>                                 
                </div>
                <div>
                <div className="w-20 label-text">
                  <p>{`${expedition.attributes.Poeng} poeng`}</p>
                  </div>   
                </div>
              </div>
              
              <div className="flex py-1">
                <div>
                  <div className="w-8 p-1">
                    <img src={SuitedFor} className="w-4"/>                    
                  </div>                                 
                </div>
                <div>
                <div className="w-20 label-text">
                  <p>{getCodedValue(expedition.sourceLayer, expedition.attributes.Passer_for, "Passer_for") || "Informasjon mangler"}</p>
                  </div>   
                </div>
              </div> 
              <div className="flex py-1">
                <div>
                  <div className="w-8 p-1">
                    <img src={Tech} className="w-4"/>                    
                  </div>                                 
                </div>
                <div>
                <div className="w-20 label-text">
                  <p>{getCodedValue(expedition.sourceLayer, expedition.attributes.Tilgjengelighet, "Tilgjengelighet") || "Informasjon mangler"}</p>
                  </div>   
                </div>
              </div>               
            </div>
            <div className="flex items-center ml-auto -mt-1">
            <img src={PointRight} className="w-2"/>
            </div>
          </div>
        </div>
      </div>
  );
};
