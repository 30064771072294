import React from "react";
import Explorer from "../Explorer/Explorer";
import { Link } from "react-router-dom";
import { AppContext } from "../../core/state/context";
import { useContext } from "react";

export const ExplorerFooter = () => {
  const context = useContext(AppContext);

  return (
    <div className="chalkboard-bg relative flex flex-col items-center">
      <Link className="z-10" to="/map">
        <button onClick={() => context.selectedTour.set(null)} className="text-white m-6 bg-gray-800 p-3 rounded-lg">
          <h4>Klikk her for å utforske med</h4>
          <h2 className="font-bold">KART OG FAKTA</h2>
        </button>
      </Link>
      <Explorer className="absolute w-full" />
    </div>
  );
};
