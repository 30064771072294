import React from "react";
import { useHistory } from "react-router-dom";
import {IExpeditionProps, IExpedition} from "../../core/model/interfaces";
import { AppContext } from "../../core/state/context";
import { useContext } from "react";
import { numberOfPosts, startTrip } from "../../core/services/tourservice";
import { getCodedValue } from "../../core/map/maplayerutils";
import PointRight from "../../assets/marker-right.svg"
import "./ExpeditionMapPreviewStyle.css"; 

export const ExpeditionMapPreview = (props: IExpeditionProps) => {
  const context = useContext(AppContext);
  const history = useHistory();
  
  const startExpedition = (expedition: IExpedition) => {
    startTrip(expedition, context);    
  }

  let expedition = props.expedition;

  return (
      <div className="w-full absolute z-10">
      <div className="text-white bg-gray-800 p-4 app-background">
        <div>
          <div className="flex items-stretch h-18" onClick={() => startExpedition(expedition)}>
            
            <div className="flex-1">
              <h3 className="text-xl">{expedition.attributes.Navn}</h3>
              <div className="flex flex-col">
                <div className="flex">
                  <div className="w-20 pr-2">
                    <p>{(expedition.attributes.Shape__Length/1000).toFixed(1)} km</p>
                    <p className="text-xs text-gray-400">LENGDE</p>
                  </div>
                  <div className="w-20 pr-2">
                    <p>{expedition.attributes.Varighet && expedition.attributes.Varighet.toFixed(1) + " timer" || "Ukjent"}</p>
                    <p className="text-xs text-gray-400">TID</p>
                  </div>
                  <div className="w-20 pr-2">
                    <p>{numberOfPosts(expedition, context)}</p>
                    <p className="text-xs text-gray-400">POSTER</p>
                  </div>
                  <div className="w-20 pr-2">
                    <p>{`${expedition.attributes.Poeng}`}</p>
                    <p className="text-xs text-gray-400">POENG</p>
                  </div>
                </div>
                {expedition.sourceLayer && (
                <div className="flex">
                  <div className="w-20 pr-2">
                    <p>{getCodedValue(expedition.sourceLayer, expedition.attributes.Passer_for, "Passer_for") || "Informasjon mangler"}</p>
                    <p className="text-xs text-gray-400">PASSER</p>
                  </div>
                  <div className="w-80 pr-2">
                    <p>{getCodedValue(expedition.sourceLayer, expedition.attributes.Tilgjengelighet, "Tilgjengelighet") || "Informasjon mangler"}</p>
                    <p className="text-xs text-gray-400">TILGJENGELIGHET</p>
                  </div>
                </div>
                )}
              </div>
            </div>
          </div>
          <div className="text-sm mt-4">
            {expedition.attributes.Intro}
          </div>          
          <div className="text-sm mt-4">
            {expedition.attributes.Fritekst}
          </div>          
        </div>
      </div>
          {(expedition.attributes.attachmentUris && expedition.attributes.attachmentFiles.length > 0) &&
            // <div className="flex attachment-link items-center text-white text-sm p-4">
              <a className="flex attachment-link items-center text-white text-sm p-4" href={expedition.attributes.attachmentFiles[0]} target="_blank" rel="noopener noreferrer" >
                Les mer om turen (last ned PDF)
                <div className="ml-auto w-2">
                  <img src={PointRight} />
                </div>
              </a>
            // </div>
          }
      <div className="app-orange-bg text-center p-2 font-semibold cursor-pointer" onClick={() => startExpedition(expedition)}>
              <h4>Jeg er klar for ekspedisjon!</h4>
      </div>
      </div>
  );
};
