// @ts-nocheck
import * as React from "react";
import { motion } from "framer-motion";

function Explorer(props) {
  return (
    <svg viewBox="0 0 356 203" {...props}>
      <title>{"explorer"}</title>
      <defs>
        <filter
          x="-8.3%"
          y="-3%"
          width="116.7%"
          height="112%"
          filterUnits="objectBoundingBox"
          id="prefix__filter-3"
        >
          <feOffset dy={2} in="SourceAlpha" result="shadowOffsetOuter1" />
          <feGaussianBlur
            stdDeviation={0.5}
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
          />
          <feColorMatrix
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.0775513549 0"
            in="shadowBlurOuter1"
          />
        </filter>
        <filter
          x="-8.3%"
          y="-8.3%"
          width="116.7%"
          height="116.7%"
          filterUnits="objectBoundingBox"
          id="prefix__filter-5"
        >
          <feOffset dy={3} in="SourceAlpha" result="shadowOffsetInner1" />
          <feComposite
            in="shadowOffsetInner1"
            in2="SourceAlpha"
            operator="arithmetic"
            k2={-1}
            k3={1}
            result="shadowInnerInner1"
          />
          <feColorMatrix
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.109839379 0"
            in="shadowInnerInner1"
          />
        </filter>
        <linearGradient
          x1="50%"
          y1="0%"
          x2="50%"
          y2="42.732%"
          id="prefix__linearGradient-1"
        >
          <stop stopColor="#D2DCAF" offset="0%" />
          <stop stopColor="#9BCBC6" offset="100%" />
        </linearGradient>
        <rect id="prefix__path-4" x={0} y={0} width={18} height={18} rx={9} />
        <path
          d="M18 0c6.627 0 12 5.373 12 12v25c0 7.18-5.82 13-13 13h-4C5.82 50 0 44.18 0 37V12C0 5.373 5.373 0 12 0h6zm7.137 34.072c-.758.689-4.091 1.033-10 1.033s-9.242-.344-10-1.033c-1.047 6.667 2.283 10 9.992 10 7.708 0 11.044-3.333 10.008-10z"
          id="prefix__path-2"
        />
      </defs>
      <g
        id="prefix__Prototype"
        stroke="none"
        strokeWidth={1}
        fill="none"
        fillRule="evenodd"
      >
        <g id="prefix__Homepage---logged-in" transform="translate(0 -745)">
          <g
            id="prefix__section:-search-and-learn"
            transform="translate(0 688)"
          >
            <g id="prefix__explorer" transform="translate(0 57)">
              <path
                id="prefix__Rectangle-2"
                fill="#447189"
                d="M0 0h356v203H0z"
              />
              <path
                id="prefix__Rectangle"
                fill="url(#prefix__linearGradient-1)"
                d="M0 99h356v104H0z"
              />
              <g
                id="prefix__dino"
                transform="translate(31 65)"
                fill="#3A423F"
                fillRule="nonzero"
              >
                <path
                  d="M7.5 0C5.588 0 4 1.825 4 4c.092 0-4 .895-4 8 0 5.382 1 8.204 1 12h14s-1.167-3.037-2.367-4.053C7.303 19.947 4 19.575 4 15c.184 3.139 6.623 3 8 3 1.377 0 2.451 1 5 1 2.565 0 5-.858 5-4 0 0-.522.031-1.025.055-.13-1.402-.973-2.055-.973-2.055s-.856.623-.979 2.072a29.368 29.368 0 01-2.058-.127c-.161-1.328-.963-1.945-.963-1.945s-.71.514-.932 1.695c-.405-.073-.806-.16-1.203-.261-.27-.974-.865-1.434-.865-1.434s-.402.288-.697.93c-.832-.334-1.592-.753-2.2-1.297C9.29 11.88 8.525 10.125 7 9h2.086c.236 1.114.912 1.64.912 1.64s.686-.499.92-1.64H11c.405 0 .738.054 1.05.129.201 1.225.948 1.795.948 1.795s.527-.382.816-1.24c.35.096.77.176 1.254.234.219 1.172.93 1.723.93 1.723s.694-.505.924-1.66c.39-.01.78-.022 1.148-.053.22 1.165.928 1.713.928 1.713s.823-.599.97-1.989c.405-.08.784-.169 1.137-.27.252 1.056.893 1.556.893 1.556s.952-.683.994-2.301C23.62 8.28 24 7.907 24 7.578 24 4.86 22.61.821 18.455 2.33 17.615 2.13 14.05 1.57 12 1c0 0 1.261 2.447 2 3-3.304 0-2.752-4-6.5-4zM8 2l2 2.57C10 5.618 9.64 6 9 6v-.957L8 3.672S7 4.309 7 6c-.452 0-1-.402-1-2 0-1.108.546-2 2-2zm13 2c.457 0 1 .446 1 1.463C22 6.439 21.641 7 21 7s-1-.541-1-1.537C20 4.487 20.543 4 21 4z"
                  id="prefix__Shape"
                />
              </g>
              <motion.g
                transition={{ duration: 5, loop: Infinity }}
                animate={{ y: [-3, 0, -1, 0, -3], rotate: [1, -2, 1] }}
              >
                <motion.g
                  transition={{ duration: 5, loop: Infinity }}
                  animate={{ rotate: [1, -5, 1] }}
                >
                  <g
                    id="prefix__magnifyglass"
                    transform="scale(-1 1) rotate(23 -375.206 -480.955)"
                    fill="#FEFEFE"
                    fillRule="nonzero"
                  >
                    <path
                      d="M11.884 0C5.33 0 0 5.381 0 12c0 6.62 5.329 12.001 11.884 12.001 2.595 0 4.991-.853 6.947-2.283L28.023 31 30 29.004l-9.107-9.197a12.009 12.009 0 002.875-7.806C23.768 5.38 18.44 0 11.884 0zm0 1.412c5.8 0 10.486 4.732 10.486 10.589 0 5.856-4.686 10.589-10.486 10.589-5.8 0-10.486-4.733-10.486-10.59 0-5.856 4.687-10.588 10.486-10.588z"
                      id="prefix__Shape"
                    />
                  </g>
                </motion.g>
                <g transform="translate(147 116)" id="prefix__maskot">
                  <path
                    d="M23.147 56.81c1.089-1.74 2.765-.94 3.353 0 .588.939 0 4.435 0 5.358 0 .922.99.492 1.5 1.863s.952 3.451-1.5 4.027c-2.452.575-1.843-.675-4.465-.987-2.623-.312-4.23.596-7.172.596-2.941 0-2.462-4.47 0-5.5 2.462-1.028 3.924-1.028 7.172 0 1.837-1.415.024-3.618 1.112-5.358zm12.938 0c1.088 1.739-.725 3.942 1.112 5.358 3.247-1.03 4.71-1.03 7.171 0 2.462 1.029 2.942 5.499 0 5.499-2.941 0-4.549-.908-7.171-.596-2.622.312-2.013 1.562-4.465.987-2.453-.576-2.01-2.656-1.5-4.027.51-1.371 1.5-.94 1.5-1.863s-.588-4.42 0-5.359c.588-.94 2.264-1.74 3.353 0z"
                    id="prefix__legs"
                    fill="#F7574F"
                  />
                  <path
                    d="M38.89 24.66l13.79 10.91a5 5 0 11-2.397 6.092L34.449 30.575l4.442-5.915z"
                    id="prefix__arm"
                    fill="#F7574F"
                    transform="rotate(-67 47.225 34.83)"
                  />
                  <path
                    d="M6.89 34.66l13.79 10.91a5 5 0 11-2.397 6.092L2.449 40.575l4.442-5.915z"
                    id="prefix__arm"
                    fill="#F7574F"
                    transform="rotate(113 15.225 44.83)"
                  />

                  <g id="prefix__body" transform="translate(14.863 8.928)">
                    <path
                      d="M12 0h6c6.627 0 12 5.373 12 12v25c0 7.18-5.82 13-13 13h-4C5.82 50 0 44.18 0 37V12C0 5.373 5.373 0 12 0z"
                      fill="#6D0F0F"
                    />
                    <g id="prefix__mouth" transform="translate(8.284 34.072)">
                      <path
                        d="M6.853 6.033c3.863 0 8.088 1.984 6.978 2.469C11.699 9.432 9.829 10 6.845 10c-2.68 0-4.691-.422-6.45-1.208-1.737-.775 2.595-2.759 6.458-2.759z"
                        id="prefix__Rectangle"
                        fill="#BA2B25"
                      />
                      <path
                        d="M7.353 8a1.5 1.5 0 011.5 1.5v1a1.5 1.5 0 01-1.5 1.5h-1a1.5 1.5 0 01-1.5-1.5v-1a1.5 1.5 0 011.5-1.5h1zm2.5-8a1 1 0 011 1v2a1 1 0 01-1 1h-1a1 1 0 01-1-1V1a1 1 0 011-1h1zm-6 0a1 1 0 011 1v2a1 1 0 01-1 1h-1a1 1 0 01-1-1V1a1 1 0 011-1h1z"
                        id="prefix__Rectangle-3"
                        fill="#FFFFFE"
                      />
                    </g>
                    <g id="prefix__body-2">
                      <use
                        fill="#000"
                        filter="url(#prefix__filter-3)"
                        xlinkHref="#prefix__path-2"
                      />
                      <use fill="#F7574F" xlinkHref="#prefix__path-2" />
                    </g>
                  </g>
                  <motion.g
                    animate={{ scaleY: [1, 0, 1] }}
                    transition={{
                      duration: 0.25,
                      loop: Infinity,
                      repeatDelay: 4
                    }}
                  >
                    <g id="prefix__eye" transform="translate(20.863 20.928)">
                      <g id="prefix__Rectangle">
                        <use fill="#FFF" xlinkHref="#prefix__path-4" />
                        <use
                          fill="#000"
                          filter="url(#prefix__filter-5)"
                          xlinkHref="#prefix__path-4"
                        />
                      </g>
                      <circle
                        id="prefix__Oval"
                        fill="#06B8F7"
                        cx={9}
                        cy={10}
                        r={4}
                      />
                      <path
                        d="M9 8a2 2 0 01.594.09 1 1 0 001.253 1.144A2 2 0 119 8z"
                        id="prefix__Oval-2"
                        fill="#0A4668"
                      />
                    </g>
                  </motion.g>
                  <g id="prefix__hat" transform="translate(12)">
                    <path
                      d="M17.863 0c3.403 0 9.168 2.988 11.895 6.122 1.537 1.765 2.572 5.034 3.105 9.806h-30c.115-4.781.827-8.05 2.137-9.806C7.45 2.836 14.46 0 17.863 0z"
                      id="prefix__Rectangle"
                      fill="#F5A771"
                    />
                    <path
                      d="M16.863.076a6.64 6.64 0 012 0v10.852h-2V.076z"
                      id="prefix__Rectangle"
                      fill="#EA8F5C"
                    />
                    <path
                      d="M17.863 7.964c7.89 0 11.379 1.587 14.106 2.964l.894 5h-30l.406-5C4.2 9.39 9.973 7.964 17.863 7.964z"
                      id="prefix__Rectangle"
                      fill="#B04916"
                    />
                    <path
                      d="M.827 15.186c3.557-2.54 9.748-4.258 16.036-4.258 6.288 0 14.56 1.849 18.415 4.258 2.143 3.297 1.338 5.094-2.415 5.393v-1.606c-3.869-2.607-9.699-2.485-16-2.485-5.415 0-10.862.56-13.952 2.485v2.176l-.83-.22c-2.262-.65-2.68-2.564-1.254-5.743z"
                      id="prefix__Rectangle"
                      fill="#F5A771"
                    />
                  </g>
                </g>
              </motion.g>

              <path
                id="prefix__Path-2"
                fill="#6A9AA4"
                d="M0 52.603l14.892 3.314L27.55 84.155l20.21 8.492 13.179 34.352 10.22 5.907 3.636 10.723H0z"
              />

              <path
                id="prefix__Path-3"
                fill="#2E4E63"
                d="M0 151l10 5.441 3.793 16.387 9.179 3.81 5.743 6.794 14.285 5 24.598 3.234v4.982l10.12 3.14L85.527 203h51.1l4.4-11.334H148l7.259-8.234H199.5l-2.5 8.234 14-3.234 6 3.234 5.54 8.122 3.75 3.212H0z"
              />
              <motion.g
                animate={{ translateX: [0, -2, 0, 3, 1, 2, 0] }}
                transition={{
                  duration: 5,
                  loop: Infinity
                }}
              >
                <path
                  id="prefix__Path-4"
                  fill="#577B83"
                  d="M248.914 148.629l12-87.723 11.227-14.055 11.584 5.752h15.614l10.058 14.561v14.707l8.276 11.892-3.31 5.237 7.023 7.5 4.867-3.97 5.336 7.687 5.219 38.412z"
                />
              </motion.g>
              <path
                id="prefix__Path-5"
                fill="#507064"
                d="M303.162 168.044l-4.905-32.544 13.844-22 15.193-8.384 12.101-40.75L348 69.554l5 98.491z"
              />
              <path
                id="prefix__Path-6"
                fill="#2C4963"
                d="M263.502 177l18.307-5.064 7.003-10.892L315.752 151l6.303 10.044h14.753l4.33 6.83 9.362 4.062V177z"
              />
              <motion.g
                animate={{ translateX: [0, -2, 0, 3, 1, 2, 0] }}
                transition={{
                  duration: 5,
                  loop: Infinity
                }}
              >
                <path
                  id="prefix__Path-8"
                  fill="#6898A6"
                  d="M113.145 133.294L118.5 116l8.103-5.783 6.17-3.717 4.086 17.561v9.233z"
                />
              </motion.g>
              <motion.g
                animate={{ translateX: [0, -2, 0, 3, 1, 2, 0] }}
                transition={{
                  duration: 5,
                  loop: Infinity
                }}
              >
                <path
                  id="prefix__Path-7"
                  fill="#0B2035"
                  d="M356 188.535h-32.74L333.254 177l9.863-4.883L350 164h4.285z"
                />
              </motion.g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default Explorer;
