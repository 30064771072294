import React, { useContext, useState, useEffect } from "react";
import { AppContext } from "../../core/state/context";
import closeBtn from "../../img/icons8-cancel@2x.png";
import QrReader from 'react-qr-reader'

export const QrReaderWidget = (props: any) => {
    const context = useContext(AppContext);
    const [qrActive, setQrActive] = useState(false);

    useEffect(() => {
      setQrActive(context.qrActive.value);
    }, [context.qrActive.value])
    
    
    const handleScan = (data: any) => {
      if (data) {
          context.qrReaderData.set(data);
          context.qrActive.set(false)
      }
    }
    const handleError = (err: any) => {
      alert("there was an error reading the QR-Code. " + err);
    }

    return (<div className="qr-scan">
           { qrActive &&
          <div className="fixed z-50 top-0 bottom-0 right-0 left-0">
            <button
                className="w-6 z-40 h-6 bg-gray-500 hover:bg-gray-700 rounded-full m-2 absolute top-0 right-0 flex items-center justify-center"
                onClick={() => context.qrActive.set(false)}
            ><img src={closeBtn} alt="" /></button>
          <QrReader
          delay={300}
          onError={handleError}
          onScan={handleScan}
          style={{ position: "absolute", top: "0", bottom: "0", left: "0", right: "0", width: "100%", height: "100%" }}
        /></div>
          }
          </div>);
  };