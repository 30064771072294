import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { loadModules } from "esri-loader";
import "./App.css";
import { HeaderView } from "./components/Header/HeaderView";
import { LoadingAnimation } from "./components/LoadingAnimation/LoadingAnimation";

import { MapPage } from "./screens/MapPage";
import { HomePage } from "./screens/HomePage";
import { AboutPage } from "./screens/AboutPage";
import { HelpPage } from "./screens/HelpPage";
import { ToursPage } from "./screens/ToursPage";
import { TourPage } from "./screens/TourPage";
import { StatsPage } from "./screens/StatsPage";
import { RegistrationPage } from "./screens/RegistrationPage/RegistrationPage";
import { gettoken } from "./core/services/token";
import { AppContext } from "./core/state/context";
import { IframePage } from "./screens/IframePage";
import { QrReaderWidget } from "./components/Quiz/QrReaderWidget";

function App() {
  const [token, setToken] = useState(null);
  const [agentname, setAgentname] = useState("");
  const [tours, setTours] = useState(null);
  const [tourStops, setTourStops] = useState(null);
  const [activeFeatures, setActiveFeatures] = useState([]);
  const [selectedFeature, setSelectedFeature] = useState(null);
  const [iframeURL, setIframeURL] = useState(null);
  const [selectedTour, setSelectedTour] = useState(null);
  const [domainValues, setDomainValues] = useState(null);
  const [mapFilter, setMapFilter] = useState("");
  const [activeTour, setActiveTour] = useState(null);
  const [startedTour, setStartedTour] = useState(null);
  const [tourFilter, setTourFilter] = useState(null);
  const [tourSort, setTourSort] = useState(null);
  const [currentStop, setCurrentStop] = useState(null);
  const [pageName, setPageName] = useState("/");
  const [qrReaderActive, setQrReaderActive] = useState(false);
  const [qrReaderData, setQrReaderData] = useState(false);
  const [position, setPosition] = useState(null);
  const store = {
    token: { value: token, set: setToken },
    agent: { value: agentname, set: setAgentname },
    tours: { value: tours, set: setTours },
    tourstops: { value: tourStops, set: setTourStops },
    activeFeatures: { value: activeFeatures, set: setActiveFeatures },
    selectedFeature: { value: selectedFeature, set: setSelectedFeature },
    iframeURL: { value: iframeURL, set: setIframeURL },
    selectedTour: { value: selectedTour, set: setSelectedTour },
    domainValues: { value: domainValues, set: setDomainValues },
    mapFilter: { value: mapFilter, set: setMapFilter },
    activeTour: { value: activeTour, set: setActiveTour },
    inProgress: { value: startedTour, set: setStartedTour },
    activeStop: { value: currentStop, set: setCurrentStop },
    tourFilter: { value: tourFilter, set: setTourFilter },
    historyName: { value: pageName, set: setPageName },
    qrActive: { value: qrReaderActive, set: setQrReaderActive},
    qrReaderData: { value: qrReaderData, set: setQrReaderData},
    tourSort: { value: tourSort, set: setTourSort },
    position: {value: position, set: setPosition}
  };

  useEffect(() => {
    loadModules(["esri/config"]).then(([esriConfig]) => {
      gettoken().then((data: any) => {
        store.token.set(data);

        if(!store.tours.value) {
          
        }
      });      
    });
  }, [agentname]);

  return (
    (store.token.value && (
      <AppContext.Provider value={store}>
        <Router>
          <div className="flex flex-col antialiased">
            <HeaderView />
            <div className="app-background flex flex-col pt-12 overflow-hidden h-screen">
              <Switch>
                <Route path="/map" component={MapPage} />
                <Route path="/about" component={AboutPage} />
                <Route path="/help" component={HelpPage} />
                <Route path="/tours" component={ToursPage} />
                <Route path="/tour/:tourid" component={TourPage} />
                <Route path="/quiz/:quizid" component={MapPage} />
                <Route path="/home" component={HomePage} />
                <Route path="/stats" component={StatsPage} />

                <Route path="/" component={RegistrationPage} />
              </Switch>
              <IframePage />
              <QrReaderWidget/>
            </div>
          </div>
        </Router>
      </AppContext.Provider>
    )) || <LoadingAnimation />
  );
}

export default App;
