import React, { useState, useEffect } from "react";
import { AppContext } from "../../core/state/context";
import { useContext } from "react";
import { Link, useLocation, useHistory } from "react-router-dom";
import maskot from "../../img/maskot.svg";
import { appSettings } from "../../core/settings";
import svgback from "../../assets/svgback.svg";

import "./HeaderStyle.css";
import { cancelCurrentTrip } from "../../core/services/tourservice";
export const HeaderView = () => {
  const [backbuttonEnable, setBackbuttonEnable] = useState(false);
  const [udpateView, setUdpateView] = useState(0);
  const context = useContext(AppContext);
  const history = useHistory();
  const location = useLocation();
  useEffect(() => {
    console.log("pathname", location.pathname);
    let isAtHome = ["/", "/home"].indexOf(location.pathname) > -1;
    let isAtExpList = ["/tours"].indexOf(location.pathname) > -1;

    setBackbuttonEnable(!isAtHome);
    
    if (isAtExpList) {
      context.selectedFeature.set(null);
    }

    if (isAtHome) {
      cancelCurrentTrip(context);
    }

    // if(location.state) {
    //   context.historyName.set((location.state as any)["prevPath"]);
    // }
  }, [location, context]);
  const [overlay, setOverlay] = useState(false);

  const changeAgent = () => {
    if (window.confirm("Vil du bytte agent?")) {
      context.agent.set("");
      history.push("/");
    } else {
    }
  };

  const goBack = () => {
    history.goBack();
    setUdpateView((udpateView) => ++udpateView);
  };
  let ppath = location.state ? (location.state as any)["prevPath"] : "/";

  return (
    <div>
      <div className="font-sans antialiased h-16 fixed left-0 right-0 top-0 z-20">
        <span style={{ display: "none" }}>{udpateView}</span>
        <nav className="flex items-center justify-between flex-wrap headerColor">
          <div className="flex items-center flex-no-shrink text-white mr-6 p-4">
            {(backbuttonEnable && (
              <button className="flex" onClick={goBack}>
                <img className="flex" src={svgback} />
                <div className="h-4 pl-2 flex flex-col justify-center">
                  {appSettings.friendlypagenames[ppath]}
                </div>
              </button>
            )) || (
              <button onClick={() => setOverlay(!overlay)}>
                <label className="pointer-cursor block">
                  <svg
                    className="fill-current text-gray-900 iconColor"
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                  >
                    <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"></path>
                  </svg>
                </label>
              </button>
            )}
          </div>
          <div className="flex flex-row items-center">
            <div className="iconColor pr-3 font-bold">
              <button onClick={changeAgent}>
                <p>Geo-agent</p>
                <p>{context.agent.value}</p>
              </button>
            </div>
            <div className="flex-grow">
              <img
                className="h-10 overflow-hidden mr-2"
                src={maskot}
                alt="Maskot"
              />
            </div>
          </div>
        </nav>
      </div>
      {/* OVERLAY */}

      <div
        className={
          "fixed w-full z-20 overlay-bg top-0 left-0 hide-scrollbar " +
          (overlay ? "h-full" : "h-0")
        }
      >
        <button
          className="p-4 -mt-4 text-white text-5xl"
          onClick={() => setOverlay(!overlay)}
        >
          &times;
        </button>
        <div className="text-white relative w-full text-center mt-16">
          <Link
            to={{ pathname: "/home", state: { prevPath: location.pathname } }}
            onClick={() => setOverlay(false)}
            className="block text-orange-500 p-4 text-3xl"
            href="#"
          >
            Hjem
          </Link>
          <Link
            to={{ pathname: "/tours", state: { prevPath: location.pathname } }}
            onClick={() => setOverlay(false)}
            className="block text-orange-500 p-4 text-3xl"
            href="#"
          >
            Alle ekspedisjoner
          </Link>
          <Link
            to={{ pathname: "/map", state: { prevPath: location.pathname } }}
            onClick={() => setOverlay(false)}
            className="block text-orange-500 p-4 text-3xl"
            href="#"
          >
            Utforsk i kart
          </Link>
          <Link
            to={{ pathname: "/help", state: { prevPath: location.pathname } }}
            onClick={() => setOverlay(false)}
            className="block text-orange-500 p-4 text-3xl"
            href="#"
          >
            Hjelp
          </Link>
          <Link
            to={{ pathname: "/about", state: { prevPath: location.pathname } }}
            onClick={() => setOverlay(false)}
            className="block text-orange-500 p-4 text-3xl"
            href="#"
          >
            Om appen
          </Link>
          <Link
            to={{ pathname: "/stats", state: { prevPath: location.pathname } }}
            onClick={() => setOverlay(false)}
            className="block text-orange-500 p-4 text-3xl"
            href="#"
          >
            Toppliste
          </Link>
        </div>
      </div>
    </div>
  );
};
