import React, { useContext, useState, useEffect } from "react";
import { AppContext } from "../../core/state/context";
import QrReader from 'react-qr-reader'
import "./QuizStyle.css";

export const QuizObject = (props: any) => {
    const context = useContext(AppContext);
    
    let {
      Type_stopp
    } = props.currentStop.attributes;

    return (
      (<div>          
        <div>{( Type_stopp !== "Quiz" ? 
          (<div>
            {(context.qrReaderData.value && !context.qrReaderData.value.includes(props.currentStop.attributes.GlobalID)) && (
              <div className="py-2 text-red-500">
                <span>Ops! Koden du skannet tilhører ikke denne posten. Er du sikker på at du er på rett plass?</span>
              </div>
            )}
            <span>For å gjennomføre quiz'en må du ut på tur. Er du på plass og har QR-koden foran deg, trykker du på startknappen under. Er du ikke det, må komme deg ut for å få med deg moroa!</span> 
            <div className="text-center">
              <div onClick={() => context.qrActive.set(true)} className="startquiz-btn p-2 rounded m-2">Start quiz</div>
            </div>
          </div>)
        : 
          (<span>Beklager, ingen quiz på denne stoppen</span>))}
        </div>      
      </div>
    ));
  };