import React from "react";

export const HelpPage = () => {
  return (
    <div className="w-full h-screen overflow-scroll flex justify-center">
      <div className="flex flex-col">
        <div className="p-8 max-w-2xl">
          <h1 className="text-white text-3xl font-bold">Hjelp</h1>
          <h2 className="text-white text-xl mt-4">Vil du bli GeoAgent?</h2>
          <p className="text-white text-sm mt-4">
            Du trenger ikke være registrert GeoAgent for å bruke løsningen. 
            Den er gratis og tilgjengelig for alle. Men hvis du har lyst til å kunne samle 
            poeng og få registrert alle turer du går, kan du kontakte Randsfjordmuseet 
            på <a className="" href="mailto:geoagent@andsfjordmuseet.no">geoagent@andsfjordmuseet.no</a> for å få hjelp til dette.
          </p>
          <p className="text-white text-sm mt-4">
            Agentkoder deles ut til skoler, bedrifter og foreninger som hver får tildelt unike agentkoder og bruker disse ved innlogging. 
          </p>
          <h2 className="text-white text-xl mt-4">Ikke registrert agent?</h2>
          <p className="text-white text-sm mt-4">
            Da logger du bare inn uten. Velg [Fortsett uten kode…] og du får tilgang til all funksjonalitet. 
            Vi vil fortsatt registrere at noen har vært på tur, og også om du svarer rett på quizen.
          </p>
          <h2 className="text-white text-xl mt-4">Ekspedisjoner</h2>
          <p className="text-white text-sm mt-4">
            Hver uke vises en anbefalt ekspedisjon på forsiden. Denne vil oppdateres etter sesong, 
            vær og arrangement og skal hjelpe deg til å finne spennende nye opplevelser i nærområdet ditt.
            Resten av ekspedisjonene finner du under [Alle ekspedisjoner]. 
            Og du vil oppleve at også denne listen vil vokse med nye turer og opplevelser rundt om på hele Hadeland.
          </p>
          <h2 className="text-white text-xl mt-4">Hva finner du i løsningen?</h2>
          <p className="text-white text-sm mt-4">
            Dette er en app for alle, men tekst, quiz og innhold er tilpasset barn.
            Vi ønsker at alle skal komme seg ut i naturen, og at man kanskje kan lære noe nytt 
            om sin region uavhengig av alder. Utforsk løsningen, og klikk på lenker som dukker opp. 
            Du vil finne massevis av spennende informasjon og tema som garantert vil gi deg noen aha-opplevelser.
          </p>
          <h2 className="text-white text-xl mt-4">Webapp og dekning</h2>
          <p className="text-white text-sm mt-4">
            GeoAgent.no er en webapp. Det vil si at du er avhengig av dekning for å bruke løsningen. 
            I enkelte områder kan dekningen være litt svak og du kan oppleve at 
            det tar lang tid å laste eller at GPS viser litt feil posisjon. 
            Det er ikke noe feil, og alt vil fungere som vanlig igjen når du kommer i et område med litt bedre dekning.
          </p>
        </div>
      </div>
    </div>
  );
};
