import React from "react";

import GeodataLogo from "../img/geodata_logo.svg";
import lunner from "../img/lunner.png";
import gran from "../img/gran.png";
import jevnaker from "../img/jevnaker.png";
import sparebank from "../img/sparebank.png";
import sparebank2 from "../img/sparebank2.jpg";

export const AboutPage = () => {
  return (
    <div className="w-full h-screen overflow-scroll flex justify-center">
      <div className="flex flex-col">
        <div className="p-8 max-w-2xl">
          <h1 className="text-white text-3xl font-bold">Om appen Geoagent</h1>
          <h4 className="text-white font-thin text-xs italic">
            VERSJON: 0.4.1
          </h4>
          <p className="text-white mt-4">
          Som geoagent kan du bli med på «ekspedisjoner» tilbake i tid på Hadeland. 
          Noen ekspedisjoner dreier seg om lange tidsreiser for å forstå utviklingen 
          av landskapet som omgir oss i dag. Andre ekspedisjoner er korte tidsreiser 
          for å få innblikk i hvordan mennesker har nyttiggjort seg av geologiske ressurser. 
          Som geoagent kan du knytte natur- og kulturhistorien med perspektiver på vår tids store 
          utfordringer til utvikling av et mer bærekraftig samfunn. Vår bruk og forvaltning av 
          de geologiske ressursene må bygge på kunnskap som ikke utarmer jorda. 
          Vi må gjøre mer med mindre ressurser. Tenke globalt, handle lokalt. {" "}
          </p>
          <p className="text-white mt-4">
          For sommeren 2020 er det ca. 50 ekspedisjoner, til fots og på sykkel. 
          Noen er tilgjengelig for alle, mens andre kan være litt mer krevende. 
          Mye informasjon vil også være tilgjengelig fra hjemmet. 
          Du kan oppsøke eller lese om 300 lokaliteter med geologisk innhold. 
          Alle ekspedisjoner har minst én quiz. I tillegg er det markert ca. 500 turstopper 
          med natur- og kulturhistoriske hendelser. Undervegs i ekspedisjonene kan det 
          åpnes karthistorier som knytter ulike temaer til kartet. Alle ekspedisjoner og quizer gir poeng!
          </p>
          <p className="text-white mt-4">
          GeoAgent-appen er utviklet for bruk av lokale skoler, men det er ønskelig at alle kan være GeoAgenter. 
          Det er helt gratis å bruke geoagent.no og det kreves ingen personopplysninger! 
          Randsfjordmuseet AS står som ansvarlig for tiltaket og har sammen med geologene 
          Leif G. Kock og Ragnar Knarud og andre frivillige etablert det faglige innholdet. 
          Løsningene er testet ut på skoler. Den tekniske løsningen er etablert og utført av Geodata AS. 
          DNB Sparebankstiftelsen, SpareBankstiftelsen Gran og SpareBankstiftelsen Jevnaker Lunner Nittedal har 
          gjort det mulig å realisere prosjektet gjennom pengegaver. Allmenningene på Hadeland har også bidratt økonomisk. 
          </p>
        </div>
        {/* <div className="max-w-2xl">
          <button className="w-full h-10 bg-gray-700 text-left pl-8 pr-4 flex justify-between mb-2">
            <p className="text-white">Personvernerklæring</p>
            <svg
              viewBox="0 0 24 24"
              width="24"
              height="24"
              stroke="white"
              strokeWidth="2"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <polyline points="9 18 15 12 9 6"></polyline>
            </svg>
          </button>
          <button className="w-full h-10 bg-gray-700 text-left pl-8 pr-4 flex justify-between mb-2">
            <p className="text-white">Åpen kildekode</p>
            <svg
              viewBox="0 0 24 24"
              width="24"
              height="24"
              stroke="white"
              strokeWidth="2"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <polyline points="9 18 15 12 9 6"></polyline>
            </svg>
          </button>
        </div> */}
        <div>
          <img
                className="about-logo"
                alt="Lunner"
                src={lunner}
              />
                        <img
                className="about-logo"
                alt="Gran"
                src={gran}
              />
                        <img
                className="about-logo"
                alt="jevnaker"
                src={jevnaker}
              />
                        <img
                className="about-logo"
                alt="sparebank"
                src={sparebank}
              />
                        <img
                className="about-logo"
                alt="sparebank2"
                src={sparebank2}
              />
        </div>
        <div className="w-full">
          <p className="text-sm text-white text-center ">
            Appen er utviklet av
            <img
              className="inline h-10 align-middle"
              src={GeodataLogo}
              alt=""
            />
          </p>
        </div>
      </div>
    </div>
  );
};
