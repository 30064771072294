import React from "react";
import { loadModules, loadCss } from "esri-loader";
import * as conf from "../../config/MapConfig";
import { AppContext } from "../../core/state/context";
import { addTourMapObjects } from "../../core/map/maputils";

import { GraphicsCliked } from "../Feature/Feature";

import "./WebMapStyle.css";
import { ExpeditionMapPreview } from "../ExpeditionMapPreview/ExpeditionMapPreview";
import { FilterWidget } from "./FilterWidget";
import WebMap from "esri/WebMap";
import { ExpeditionModal } from "../Expedition/ExpeditionModal";
import { getActiveTrip } from "../../core/services/tourservice";
import Startpunkt from "../../assets/poi_startup-POI.png";

interface IMyComponentState {
  mapRef: React.RefObject<any>;
  features: any;
  expeditions: any;
  layerView: any;
  tourLayerView: any;
}

export class WebMapView extends React.Component<{match: any}, IMyComponentState> {
  static contextType = AppContext;
  view: any;

  constructor(props: any) {
    super(props);
    const mapRef = React.createRef();
    this.state = {
      mapRef,
      features: [],
      expeditions: [],
      layerView: null,
      tourLayerView: null
    };
    this.view = null;
  }

  componentDidMount() {
    let quizid = this.props.match.params.quizid;
    
    // lazy load the required ArcGIS API for JavaScript modules and CSS
    loadCss();
    loadModules([
      "esri/WebMap",
      "esri/views/MapView",
      "esri/config",
      "esri/widgets/Track",
      "esri/widgets/Search",
      "esri/widgets/BasemapGallery",
      "esri/widgets/Expand",
      "esri/widgets/ScaleBar",
      "esri/views/layers/support/FeatureFilter",
      "esri/portal/Portal"
    ]).then(([EsriWebMap, MapView, esriConfig, Locate, Search, BasemapGallery, Expand, ScaleBar, Portal]) => {
      esriConfig.request.interceptors?.push({
        urls: [
          "https://www.arcgis.com/sharing",
          "https://services9.arcgis.com",
        ],
        before: (params: any) => {
          params.requestOptions.query["token"] = this.context.token.value;
        },
        error: () => {},
      });
      var webmap: WebMap = new EsriWebMap({        
        portalItem: {
          id: this.context.selectedTour.value
            ? conf.webmapidempty
            : conf.webmapid,
        },
      });

      // load the map view at the ref's DOM node
      this.view = new MapView({
        container: this.state.mapRef.current,
        map: webmap,        
        ui: {
          components: [],
        },
      });

      var locateWidget = new Locate({
        view: this.view,
        iconClass: "esri-icon-locate",
        geolocationOptions: { maximumAge: 0, timeout: "Infinity", enableHighAccuracy: true }
      });
      locateWidget.on('track', (event:any) => {
        locateWidget.goToLocationEnabled = false
      });
      this.view.ui.add(locateWidget, "top-right");      
      
      var searchWidget = new Search({
        view: this.view,
      });
      this.view.ui.add(searchWidget, "top-left");

      var basemapWidget = new BasemapGallery({
        view: this.view,
        iconClass: "esri-icon-locate",
        source: new Portal({
          url: "https://rmgeoportal.maps.arcgis.com/home/"
        })
      });
      const expandBaseMap = new Expand({
        expandIconClass: "esri-icon-basemap",
        expandTooltip: "Bakgrunnskart",
        view: this.view,
        content: basemapWidget,
        mode: "floating",
        expanded: false,
      });

      const scaleBar = new ScaleBar({
        view: this.view,
        unit: "metric"
      });

      this.view.ui.add(scaleBar, "bottom-left");      
      this.view.ui.add(expandBaseMap, "top-left");      
      
      this.view.popup.container.setAttribute("style", "display:none");

      let pointLayerId = "Objekt_punkter_5719"; // Objekter i standard kart
      let pointLayerEmptyId = "Objekt_punkter_5079"; // Objekter i ekspedisjonskart
      let tourLayerId = "Geotur_8638";

      this.view.when(() => {
        let pointLayer: any = webmap.layers.find((layer: any) => {
          return layer.id === pointLayerId;
        });
        this.view.whenLayerView(pointLayer).then((layerView: any) => {
          let domainValues: any = {};
          for (let index = 0; index < pointLayer.fields.length; index++) {
            let field = pointLayer.fields[index];
            if (field.domain && field.domain.type === "coded-value") {
              let domain = field.domain;

              let values = domain.codedValues.map((codeValue: any) => {
                return { name: codeValue.name, code: codeValue.code };
              });
              domainValues[field.name] = {
                name: domain.name,
                codedValues: values,
              };
            }
          }

          this.context.domainValues.set(domainValues);
          this.setState({ layerView: layerView });
        });

        let tourLayer: any = webmap.layers.find((layer: any) => {
          return layer.id === tourLayerId;
        });
        this.view.whenLayerView(tourLayer).then((tourLayerView:any) => {
          tourLayer.renderer.symbol = {
            type: "picture-marker",  // autocasts as new PictureMarkerSymbol()
            url: Startpunkt,
            height: "32px",
            width: "24px"
          };
          this.setState({ tourLayerView: tourLayerView });
        });
      });

      let highlight: any;
      this.view.when(() => {
        let pointLayer: any = webmap.layers.find((layer: any) => {
          return layer.id === pointLayerId;
        });
        
        let tourLayer: any = webmap.layers.find((layer: any) => {
          return layer.id === tourLayerId;
        });
        
        this.view.whenLayerView(pointLayer).then((layerView: any) => {
          if (quizid) {
            this.context.qrReaderData.set(this.props.match.url);
            this.getObject(pointLayer, quizid);
          }   
          
          this.view.on("click", (event: any) => {
            var webmap: WebMap = new EsriWebMap({        
              portalItem: {
                id: conf.webmapidempty
              },
            });

            pointLayer
              .queryFeatures({
                geometry: this.view.toMap(event),
                where: this.context.mapFilter.value,
                distance: 10 * this.view.resolution,
                returnGeometry: false,
                outFields: "*",
              })
              .then((queryResult: any) => {
                this.setState({ features: queryResult.features });
                if (highlight) {
                  highlight.remove();
                }
                highlight = layerView.highlight(queryResult.features);
              });

              tourLayer
              .queryFeatures({
                geometry: this.view.toMap(event),
                where: "Type_stopp = 'Startpunkt'",
                distance: 10 * this.view.resolution,
                returnGeometry: false,
                outFields: "*",
              })
              .then((queryResult: any) => {
                if (queryResult.features.length > 0) {
                  highlight = layerView.highlight(queryResult.features);

                  const objectIds = queryResult.features.map((feature: any) => {return feature.attributes.OBJECTID});

                  tourLayer
                  .queryRelatedFeatures({
                    outFields: ["*"], 
                    relationshipId: 0,
                    objectIds: objectIds
                  })
                  .then((relatedQueryResult: any) => {
                    let expResults: any = [];

                    objectIds.map((oid: number) => {
                      if (relatedQueryResult[oid]) {
                        expResults.push(relatedQueryResult[oid].features[0]);
                        // this.getTrip();
                      }
                    })  

                    this.setState({expeditions: expResults})
                    // this.context.selectedTour.set(expedition);
                  })
                } else {
                  this.setState({expeditions: []})
                }
              });
          });
        });
      });

      this.view.on("click", (event: any) => {
        var screenPoint = {
          x: event.x,
          y: event.y,
        };

        this.view.hitTest(screenPoint).then((response: any) => {
          if (response.results.length) {
            let features = response.results
              .filter((result: any) => {
                return result.graphic.layer.id === "tripstops";
              })
              .map((result: any) => result.graphic);
            if (features.length === 1) {
              this.context.activeStop.set(features[0])
            }

            // Hvis i en ekspedisjon, sjekk om vi traff objekt
            let objects = response.results
              .filter((result: any) => {
                return result.graphic.layer.id === pointLayerEmptyId;
              });

            if (objects.length > 0) {
              const layer = objects[0].graphic.layer;

              layer
              .queryFeatures({
                where: 'OBJECTID='+objects[0].graphic.attributes.OBJECTID,
                outFields: "*",
              })
              .then((queryResult: any) => {
                this.context.selectedFeature.set(queryResult.features[0])
              });
            }

            
          }
        });
      });
      
      this.getTrip();
      
    });
  }

  getObject = (pointLayer: any, quizId: string) => {
    pointLayer
    .queryFeatures({
      where: `GlobalID = '${quizId}'`,
      outFields: "*",
    })
    .then((queryResult: any) => {
      this.context.selectedFeature.set(queryResult.features[0]);
    });
  }

  getTrip = () => {
    getActiveTrip(this.context).then((result: any) => {
      if(result) {
        let firstStop = this.context.tourstops.value.filter((stop: any) => stop.attributes.Tur === result.attributes.Navn && stop.attributes["Type_stopp"] === "Startpunkt");
        
        this.context.inProgress.set({completedStops: [], trip: result });
        this.context.activeStop.set(firstStop[0])
      }
      
      if (this.context.selectedTour.value || this.context.inProgress.value) {
        if (this.view.map.portalItem.id === conf.webmapidempty) {
          let expedition = this.context.selectedTour.value || this.context.inProgress.value.trip;
            addTourMapObjects(
              this.context,
              this.view,
              [expedition.attributes.Navn],
              true
            );
        } else {
          loadModules(["esri/WebMap"]).then(
            ([EsriWebMap]) => {
              this.view.map= new EsriWebMap({        
                portalItem: {
                  id: conf.webmapidempty
                },
              });
          
              this.view.when(() => {
                let expedition = this.context.selectedTour.value || this.context.inProgress.value.trip;
                addTourMapObjects(
                  this.context,
                  this.view,
                  [expedition.attributes.Navn],
                  true
                );
              });
            });
        }
      }
    });          
  }

  render() {
    if (this.context.mapFilter.value != "") {
      loadModules(["esri/views/layers/support/FeatureFilter"]).then(
        ([FeatureFilter]) => {
          // Setting objects filter
          this.state.layerView.filter = new FeatureFilter({
            where: this.context.mapFilter.value,
          });

          // Setting tourlayer filter
          if (this.context.mapFilter.value === "1=1" || this.context.mapFilter.value === "1=2") {
            this.state.tourLayerView.filter = new FeatureFilter({
              where: "1=1"
            })
          } else {
            this.state.tourLayerView.filter = new FeatureFilter({
              where: "1=2"
            })
          }

        }
      );
    }
    if((this.context.selectedTour.value && !this.context.inProgress.value) || (this.context.selectedTour.value && this.context.inProgress.value && this.context.selectedTour.value.attributes.GlobalID !== this.context.inProgress.value.trip.attributes.GlobalID)) {
      return (<div className="w-full flex">
        <div className="w-full flex-grow" ref={this.state.mapRef} ></div>
        <ExpeditionMapPreview expedition={this.context.selectedTour.value} />
        <FilterWidget numFeatures={this.state.features.length + this.state.expeditions.length}/>        
        <GraphicsCliked getTrip={this.getTrip} features={this.state.features} /></div>)
    } else if((this.context.inProgress.value && !this.context.selectedTour.value) || (this.context.inProgress.value && this.context.selectedTour.value && this.context.selectedTour.value.attributes.GlobalID === this.context.inProgress.value.trip.attributes.GlobalID)) {
      return (<div className="w-full flex">
        <div className="w-full flex-grow" ref={this.state.mapRef}></div>
        <ExpeditionModal />
        <FilterWidget numFeatures={this.state.features.length + this.state.expeditions.length}/>        
        <GraphicsCliked getTrip={this.getTrip} features={this.state.features} expeditions={[]}/></div>)
    } else {
      return (<div className="w-full flex">
        <div className="w-full flex-grow" ref={this.state.mapRef}></div>
        <FilterWidget numFeatures={this.state.features.length + this.state.expeditions.length}/>        
        <GraphicsCliked getTrip={this.getTrip} features={this.state.features} expeditions={this.state.expeditions}/></div>)
    }
    
  }
}
