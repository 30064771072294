import React, { useContext, useState, useEffect } from "react";
import { AppContext } from "../../core/state/context";

import maskot from "../../img/maskotChalk.svg";
import "../Feature/FeatureStyle.css";
import closeBtn from "../../img/icons-cancel-halfround@2x.png";
import { getCodedValue } from "../../core/map/maplayerutils";
import { getAttachmentImage, getAllTourObjects, getCurrentStop, leaveCurrentStop } from "../../core/services/tourservice";
import { Accordion } from "../Feature/Accordion";
import { ModalButton } from "../Feature/ModalButton";
import DidYouKnow from "../../assets/didyouknow.png";
import StartupPin from "../../assets/poi_startup-POI@2x.png";
import QuizPin from "../../assets/poi_quiz-POI@2x.png";
import NormalPin from "../../assets/poi_normal-POI@2x.png";
import DestinationPin from "../../assets/poi_destination-POI@2x.png"
import Startup from "../../assets/svgskilt_turstart-1.svg";
import Quiz from "../../assets/svgskilt_quizstopp-trackpoi_quiz.svg";
import Normal from "../../assets/svgskilt_normalstopp-trackpoi_normal.svg";
import Destination from "../../assets/svgskilt_finishstopp-trackpoi_finish.svg"
import Default from "../../assets/default.png";

// import { ExpeditionStopQuizObject } from "../Quiz/QuizObject";

export const ExpeditionModal = ({}: any) => {
    const context = useContext(AppContext);
    const [visible, setVisible] = useState(false);
    const [featureImage, setFeatureImage] = useState(null);
    const [tourObject, setTourObject] = useState(null);
    const [currentStop, setCurrentStop] = useState(null);
    const [stopObject, setStopObject] = useState(null);
  
    let {
      Navn
    } = context.inProgress.value.trip.attributes;

    let icon: any = {
      "StartpunktPin": StartupPin,
      "QuizPin": QuizPin,
      "Vanlig stoppPin": NormalPin,
      "Destinasjon og QuizPin": DestinationPin,
      "Startpunkt": Startup,
      "Quiz": Quiz,
      "Vanlig stopp": Normal,
      "Destinasjon og Quiz": Destination
    }

    let currStop: any;

    let allTourStops = context.tourstops.value.filter((stop: any) => stop.attributes.Tur === Navn).sort((a: any, b: any) => {
      return a.attributes["Type_stopp"] === "Startpunkt" ? -1 : 1;
    });

    useEffect(() => {
      currStop = getCurrentStop(context.inProgress.value.trip, context);
      setCurrentStop(currStop);

      if(currentStop !== null) {
        Promise.all([getAttachmentImage(currentStop), getAllTourObjects(context, [Navn], true)]).then((data: any) => {
          let result = data[0];
          if(result) {
            setFeatureImage(result);
          } else {
            setFeatureImage(Default);
          }
          try {
            let object = data[1][0].data.features.filter((obj: any) => obj.attributes.GlobalID === currentStop.attributes.Objekt_ID)[0];
            if(object) {
              Object.assign(object, { 
                sourceLayer: {
                  fields: Object.keys(object.attributes).map(field => {
                    return {
                      name: field,
                      domain: { codedValues: [{ code: "1", name: field }] }
                    };
                  }),
                  url: data[1][0].config.url.substring(0, data[1][0].config.url.match(/\/(\d+)\//).index+1),
                  layerId: data[1][0].config.url.match(/\/(\d+)\//)[1]
                }
              });
              setTourObject(object);
            } else {
              setTourObject(null);
            }
          } catch(err) {
            setTourObject(null);
          }
        })
      }
    }, [currentStop, context.activeStop.value]);

    const leaveTripStop = () => {
      leaveCurrentStop(context);
      setCurrentStop(null); 
      setVisible(false);      
    }    
  
    const sourceLayer = context.inProgress.value.trip.sourceLayer;
    let featureType = currentStop ? currentStop.attributes.Type_stopp : "";
      
    return (visible && currentStop ?
      (<div className="absolute left-0 right-0 top-0 mt-12 z-20 bottom-0 app-background flex flex-col border-gray-800 border-2 z-30">
        <button
          className="w-18 h-6 bg-gray-500 hover:bg-gray-700 rounded-full absolute top-0 right-0 flex items-center justify-center"
          onClick={() => leaveTripStop()}
        >
          <div style={{ color: "#000", display: "flex" }}>
            <span style={{ fontWeight: 600, borderRadius: ".85rem 0 0 .85rem", backgroundColor: "#DCBA52", padding: "0 .25rem 0 .25rem" }}>Ferdig</span>
            <img src={closeBtn} className="h-6 w-6" alt="" />
          </div>
          
        </button>
        <div className="overflow-y-scroll hide-scrollbar flex flex-col items-center">
        <div className="expedition-caption z-10 mt-4">  
          <div className="expedition-title">
            <img src={`${icon[currentStop.attributes.Type_stopp]}`} className="w-6 ml-1" alt="Turstopp"/>
            <span>{currentStop ? currentStop.attributes["Navn"] : ""}</span></div>            
          </div>
          <div className="expedition-title-skilt-stang -mt-1">&nbsp;</div>
        <div className="expedition-description">
          <h4>{featureType}</h4>
          <p>{currentStop ? currentStop.attributes["Kort_beskrivelse"] : ""}</p>
        </div>
          {featureImage && (
            <img
              className="pictureHeight m-2"
              alt=""
              src={featureImage.indexOf(";base64,") > -1 ? featureImage : (featureImage + "?token=" + context.token.value)}
            />
          )}
        <div className="flex" style={{ width: "100%", flexDirection: "column" }}>
        {/* { currentStop &&
          <Accordion
            longText={<ExpeditionStopQuizObject currentStop={currentStop}/>}
            featureType={featureType}            
            name={"Hva er spesielt her, Og kanskje en QUIZ?"}
          />
        } */}
          
          {currentStop.attributes.StoryMap && (
            <ModalButton
              text={"Se karthistorie"}
              onClick={() => {
                context.iframeURL.set(currentStop.attributes.StoryMap);
              }}
            />
          )}
          {tourObject && (
            <ModalButton
              text={`Lær mer om ${tourObject.attributes.Navn}`}
              onClick={() => {
                context.selectedFeature.set(tourObject);
              }}
            />
          )}
          {currentStop.attributes.ekstern_lenke && (
            <ModalButton
              text={"Ekstern lenke"}
              onClick={() => {
                context.iframeURL.set(currentStop.attributes.ekstern_lenke);
              }}
            />
          )}
        </div>
        </div>
      </div>
    ) : (!visible && currentStop ?
      (<div className="flex flex-col absolute bottom-0 left-0 right-0 app-background p-2 z-30">
        { context.inProgress.completedStops ?
        <>There is progress here</> : 
        <div className="trip-bottom-help">
          <div className="current-stop">
          <div className="stop-id w-16 mw-16" style={{ minWidth: "4rem" }}>
          <img src={`${icon[`${currentStop.attributes.Type_stopp}Pin`]}`}
                alt="" className="h-8 w-6"
              />
            </div><div className="stop-description">
            <span>{ currentStop.attributes.Type_stopp === "Startpunkt" ? "Finn frem til ekspedisjonens startpunkt." : ""}</span> 
            <span>{currentStop.attributes.Kort_beskrivelse}</span>
          </div>
          </div>
          <hr />
          <div className="stop-control">
            <div className="stop-control-left">
            <img
                className="h-16 overflow-hidden mr-2"
                src={maskot}
                alt="Maskot"
              />
            </div>
            <div className="stop-control-right">              
              <a href="#" onClick={() => setVisible(true)}>Fremme ved posten? Trykk her ></a>
            </div>
          </div>
          </div>}


      </div>
    ): (<div className="flex flex-col absolute bottom-0 left-0 right-0 app-background p-2 z-30">
      <div className="trip-bottom-help">
        <h4 style={{ fontWeight: "bold" }} className="m-4">Velg din neste stopp i kartet!</h4>
        <hr/>
        <span>&nbsp;</span>
      </div>
      </div>)));
  };