import axios from "axios";
import qs from 'querystring';
import { appSettings } from "../settings";
import { IExpedition } from "../model/interfaces";
import { loadModules } from "esri-loader";

  export const getNumVisits = (token: string) => {
    return new Promise((resolve, reject) => {
      loadModules([
        "esri/tasks/QueryTask",
        "esri/tasks/support/Query",
      ]).then(([QueryTask, Query]) => {
        const query = new Query({
          where: "1=1",
          f: "json"
        });
        const queryTask = new QueryTask({
          url: appSettings.serviceBaseUrl + "/" + appSettings.movementsTable + "?token=" + token,
        });
        queryTask
          .executeForCount(query)
          .then((result: any) => {
            resolve(result);
          });
      });
    });
  };

  export const getCompletedExpeditions = (token: string) => {
    return new Promise((resolve, reject) => {
      loadModules([
        "esri/tasks/QueryTask",
        "esri/tasks/support/Query",
      ]).then(([QueryTask, Query]) => {
        const query = new Query({
          where: "Type_turstopp='Destinasjon og Quiz'",
          f: "json"
        });
        const queryTask = new QueryTask({
          url: appSettings.serviceBaseUrl + "/" + appSettings.movementsTable + "?token=" + token,
        });
        queryTask
          .executeForCount(query)
          .then((result: any) => {
            resolve(result);
          });
      });
    });
  };

  export const getNumCorrectAnswers = (token: string) => {
    return new Promise((resolve, reject) => {
      loadModules([
        "esri/tasks/QueryTask",
        "esri/tasks/support/Query",
      ]).then(([QueryTask, Query]) => {
        const query = new Query({
          where: "Riktig_Feil='Riktig'",
          f: "json"
        });
        const queryTask = new QueryTask({
          url: appSettings.serviceBaseUrl + "/" + appSettings.movementsTable + "?token=" + token,
        });
        queryTask
          .executeForCount(query)
          .then((result: any) => {
            resolve(result);
          });
      });
    });
  };

  export const getUniqueExpeditions = (token: string) => {
    return new Promise((resolve, reject) => {
      loadModules([
        "esri/tasks/QueryTask",
        "esri/tasks/support/Query",
      ]).then(([QueryTask, Query]) => {
        const query = new Query({
          where: "Tur_ID <> 0",
          outFields: ["Tur_ID"],
          returnDistinctValues: true,
          f: "json"
        });
        const queryTask = new QueryTask({
          url: appSettings.serviceBaseUrl + "/" + appSettings.movementsTable + "?token=" + token,
        });
        queryTask
          .execute(query)
          .then((result: any) => {
            resolve(result.features.length);
          });
      });
    });
  };

  export const getMostVisitedExpeditions = (token: string) => {
    return new Promise((resolve, reject) => {
      loadModules([
        "esri/tasks/QueryTask",
        "esri/tasks/support/Query",
      ]).then(([QueryTask, Query]) => {
        const query = new Query({
          where: "1=1",
          outFields: ["Tur_Navn"],
          groupByFieldsForStatistics: ["Tur_Navn"],
          outStatistics: [{"onStatisticField":"Tur_Navn","outStatisticFieldName":"countOFTur_Navn","statisticType":"count"}],
          orderByFields: ["countOFTur_Navn DESC"],
          f: "json",
          num: 5
        });
        const queryTask = new QueryTask({
          url: appSettings.serviceBaseUrl + "/" + appSettings.movementsTable + "?token=" + token,
        });
        queryTask
          .execute(query)
          .then((result: any) => {
            resolve(result.features);
          });
      });
    });
  };

  export const getMostActiveAgents = (token: string) => {
    return new Promise((resolve, reject) => {
      loadModules([
        "esri/tasks/QueryTask",
        "esri/tasks/support/Query",
      ]).then(([QueryTask, Query]) => {
        const query = new Query({
          where: "1=1",
          outFields: ["Agentkode"],
          groupByFieldsForStatistics: ["Agentkode"],
          outStatistics: [{"onStatisticField":"Poeng_Quiz + Poeng_Destinasjon","outStatisticFieldName":"sumPoints","statisticType":"sum"}],
          f: "json",
          num: 5
        });
        const queryTask = new QueryTask({
          url: appSettings.serviceBaseUrl + "/" + appSettings.movementsTable + "?token=" + token,
        });
        queryTask
          .execute(query)
          .then((result: any) => {
            resolve(result.features);
          });
      });
    });
  };


