import React from "react";
import {IExpeditionProps} from "../../core/model/interfaces";
import { ExpeditionItemHomePageView } from "./ExpeditionItemHomePageView";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import ExpeditionDude from "../../assets/svgexpedition.svg";
import PointRight from "../../assets/marker-right.svg"

export const ExpeditionView = (props: IExpeditionProps) => {
  const history = useHistory();
  return (
    <div className="flex flex-col pl-6 py-6">
      <div className="all-expeditions-link-container p-2">
      <Link
            to={{pathname: '/tours', state: { prevPath: history.location.pathname }}}
            className="flex"
            href="#">
              <img className="flex w-6" src={ExpeditionDude}/>
              <div className="flex pl-2">Sjekk ut alle ekspedisjoner her</div>
              <svg
          className="transform transition-all duration-500 ease-in-out stroke-current my-1"
          viewBox="0 0 16 16"
          width="16"
          height="16"
          stroke="white"
          strokeWidth="2"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <polyline points="8 12 12 8 8 4"></polyline>
        </svg>
        </Link>
      </div>
      <ExpeditionItemHomePageView expedition={props.expedition}/>
      
    </div>
  );
};
