import React, { useState, useEffect } from "react";
import { AppContext } from "../../core/state/context";
import { useContext } from "react";
import svgclose from "../../assets/svgclose.svg";
import svgcheckmark from "../../assets/svgcheckmark.svg";
import svgfilter from "../../assets/svgfilter-1.svg";
import svgsort from "../../assets/svgsort.svg";
import svgradioInactive from "../../assets/svgradiobutton_inactive.svg";
import svgradioActive from "../../assets/svgradiobutton_active.svg";

import "./FilterStyle.css";
import { SortMode } from "../../core/model/interfaces";

interface Props {
  toursLength: number;
  filteredLength: number;
}

export const FilterView = (props: Props) => {
  const context = useContext(AppContext);
  const [filterVisible, setFilterVisible] = useState(false); 
  const [sortVisible, setSortVisible] = useState(false); 
  const [filter, setFilter] = useState([]);
  const [sortMode, setSortMode] = useState(null);
  const filterFields = ["Type", "Passer_for", "Tilgjengelighet"];
  const sortModes: SortMode[] = [
    // {
    //   display: "Nærmeste (basert på din posisjon)",
    //   attribute: "position",
    //   direction: null 
    // },
    {
      display: "Lengde på tur - kort til lang",
      attribute: "Shape__Length",
      direction: "asc" 
    },{
      display: "Tidsbruk på tur - kort til lang",
      attribute: "Varighet",
      direction: "asc" 
    },{
      display: "Poeng på turer - høy til lav",
      attribute: "Poeng",
      direction: "desc"
    }
  ];
  const sourceLayer = context.tours.value && context.tours.value.length > 0 ? context.tours.value[0].sourceLayer : null;

  useEffect(() => {
    if (!filterVisible && filter.length !== 0) {
      let newTourFilter: any = {}; 

        filterFields.map((field, index) => {
          const fieldValues = filter.filter((f) => f.split(";")[0]===field);
          
          if (fieldValues.length > 0) {
            newTourFilter[field] = fieldValues.map(value => {return value.split(";")[1]});
          }
          context.tourFilter.set(newTourFilter);
        })
    } else {
      context.tourFilter.set(null);
    }
  }, [filterVisible]);

  useEffect(() => {
    if (!sortVisible) {
      context.tourSort.set(sortMode);
    }
  }, [sortVisible]);

  const getFilterFieldAlias = (fieldName: string) => {
    if (sourceLayer) {
      return sourceLayer.fields.filter((field: any) => field.name === fieldName)[0].alias;
    }
  }

  const getFilterValues = (fieldName: string) => {
    if (sourceLayer) {
      return sourceLayer.fields.filter((field: any) => field.name === fieldName)[0].domain.codedValues;
    }
  }

  const toggleFilter = (fieldName: string, value: any) => {
    const filterValue = fieldName + ";" + value.code
    !filter.includes(filterValue) ? setFilter([...filter, filterValue]) : setFilter(filter.filter(f => f !== filterValue));
  }

  return (
    <div>
      <div className="flex text-center">
        <h3 className="flex justify-center w-1/3 menu-separator" onClick={() => {
          setFilterVisible(true);
          setSortVisible(false);
        }}>
          <img src={svgfilter}/>
          <div className="pl-2">Filter</div>
        </h3>
        <h3 className="flex justify-center w-1/3 menu-separator" onClick={() => {
          setSortVisible(true);
          setFilterVisible(false);  
        }}>
          <img src={svgsort}/>
          <div className="pl-2">Sorter</div>
        </h3>
      <h3 className="w-1/3">{props.toursLength ? `Viser ${props.filteredLength} av ${props.toursLength}` : ""}</h3>
      </div> 
      {filterVisible && <div className="absolute bottom-0 w-full filter-background p-5 rounded-t-lg">
        <div className="flex">
          <h3 className="w-1/3 float-left text-blue-800 text-xl" onClick={() => setFilterVisible(false)}>Ok</h3>
          <h3 className="w-1/3 text-center text-xl">Filter</h3>
          <h3 className="w-1/3 text-right text-blue-800 text-xl" onClick={() => setFilter([])}>Nullstill</h3>
        </div>
        <div>
          {filterFields.map((fieldName, fieldIndex) => {
            return (
              <div key={fieldIndex} className="pt-5">
                <div className="font-black">
                  {getFilterFieldAlias(fieldName)}
                </div>
                <div>
                  {getFilterValues(fieldName).map((value: any, filterindex: number) => {
                    return (
                      <div key={filterindex} onClick={() => toggleFilter(fieldName, value)} className="py-3 flex filter-item font-lighter">
                        {value.name}
                        {filter.includes(fieldName + ";" + value.code) && <img className="ml-auto" src={svgcheckmark}/>}
                      </div>
                    )
                  })}
                </div>
              </div>
            )
          }
          )}
          </div>
        </div>
        }
        {sortVisible && <div className="absolute bottom-0 w-full filter-background p-5 rounded-t-lg">
        <div className="flex">
          <h3 className="w-1/3 float-left text-blue-800 text-xl" onClick={() => setSortVisible(false)}>Ok</h3>
          <h3 className="w-1/3 text-center text-xl">Sorter</h3>
          <h3 className="w-1/3 text-right text-blue-800 text-xl" />
        </div>
        <div>
          {sortModes.map((mode, index) => {
            return (
              <div key={index} className="flex py-2 items-center" onClick={() => setSortMode(mode)}>
                <img src={sortMode && sortMode.display === mode.display ? svgradioActive : svgradioInactive}/>
                {mode.display}
              </div>
            )
          })}
        </div>
        </div>
        }
    </div>
  );
};
