
import { getAllTourObjects } from "../../core/services/tourservice";
import { loadModules } from "esri-loader";
import MapView from "esri/views/MapView";
import { IExpedition } from "../model/interfaces";
import { Polyline, Point, Geometry } from "esri/geometry";
import WebMap from "esri/WebMap";
import Graphic from "esri/Graphic";
import Vanligstopp from "../../assets/poi_normal-POI.png";
import Destinasjon from "../../assets/poi_destination-POI.png";
import Startpunkt from "../../assets/poi_startup-POI.png";
import Quiz from "../../assets/poi_quiz-POI.png";

export const addTourMapObjects = (context: any, view: MapView, tourGlobalIDs: (string | null)[] = null, includeStops: boolean = false) => {    
      getAllTourObjects(context, tourGlobalIDs, includeStops).then((results) => {
        loadModules([
            "esri/Map", "esri/views/MapView", "esri/Graphic", "esri/geometry/Polyline", "esri/layers/GraphicsLayer", "esri/symbols/PictureMarkerSymbol", "esri/symbols/TextSymbol", "esri/geometry/SpatialReference", "esri/geometry/projection"
          ]).then(([Map, MapView, EsriGraphic, Polyline, GraphicsLayer, PictureMarker, TextSymbol, SpatialReference, projection]) => {             
            let filteredFeatures = context.tours.value;
            let filteredStops = context.tourstops.value;
            if(tourGlobalIDs) {
              filteredFeatures = context.tours.value.filter((tour: IExpedition) => tourGlobalIDs.indexOf(tour.attributes.Navn)>-1);
              let tourIds = filteredFeatures.map((tour: IExpedition) => tour.attributes.Navn);
              filteredStops = context.tourstops.value.filter((tourstop: any) => !!tourstop.attributes.Tur && tourIds.indexOf(tourstop.attributes.Tur) > -1);
            }
            let lineSymbol: { [id: string]: any } = {
                type: "simple-line", 
                color: [68, 113, 137], 
                width: 2,
                style: "short-dash",
                cap: "square"
              };

              let polylines = filteredFeatures.map((feature: any) => {
                return new Polyline({
                  paths: feature.geometry.paths,
                  spatialReference: { wkid: 25833 }
                })
              });

              var pointSymbol = {               
                height: "32px",
                width: "24px"
              };

              let symbolsDictionary: any = {
                "Vanlig stopp": Vanligstopp,
                "Destinasjon og Quiz": Destinasjon,
                "Startpunkt": Startpunkt,
                "Quiz": Quiz
              }

              let textSymbol: { [id: string]: any } = {
                type: "text",  
                color: "white",                
                xoffset: 0,
                yoffset: -2,
                font: {  
                  size: 10,
                  family: "sans serif",
                  weight: "normal"
                }
              };

              let polylineGraphics = polylines.map((geometry: any) => {
                return new EsriGraphic({
                  geometry: geometry,
                  symbol: lineSymbol,
                  attributes: {}
                })
              });

              let graphicsesses: Graphic[] = polylineGraphics;
              let labelGraphicssess: Graphic[];
              if(filteredStops) {                
                let features = filteredStops;
                graphicsesses = graphicsesses.concat(features.map((feature: any) => { 
                    let picmarker = new PictureMarker(Object.assign(pointSymbol, { url: `${symbolsDictionary[feature.attributes.Type_stopp]}`}));
                   
                    let gfx = new EsriGraphic({
                        geometry: { type: "point", x: feature.geometry.x, y: feature.geometry.y, spatialReference: { wkid: 25833 } },
                        symbol: picmarker,
                        attributes: feature.attributes
                        
                    })
                    Object.assign(gfx, { 
                        sourceLayer: feature.sourceLayer || {
                          fields: Object.keys(feature.attributes).map(field => {
                            return {
                              name: field,
                              domain: { codedValues: [{ code: "1", name: field }] }
                            };
                          }),
                          url: results[0].config.url.substring(0, results[0].config.url.match(/\/(\d+)\//).index+1),
                          layerId: results[0].config.url.match(/\/(\d+)\//)[1]
                        }
                    });
                    return gfx;
                }));
              }   
              
              var outSpatialReference = new SpatialReference({
                wkid: 25833
              });

              let webmapObject: WebMap = view.map as WebMap;  
              webmapObject.load().then((loaded: any) => {
                projection.load().then((evt: any) => {  
                  let symGeoms = graphicsesses.filter((gfx: Graphic) => gfx.attributes.Type_stopp && gfx.attributes.Type_stopp === "Startpunkt").map((gfx: Graphic) => gfx.geometry)         
                  const symbolProjected = projection.project(symGeoms, outSpatialReference);                  

                  let symbolLayer = new GraphicsLayer({
                    graphics: graphicsesses,
                    id: "tripstops"
                  });   
                  
                  view.map.layers.on("after-add", () => {
                    setTimeout(() => {
                      symbolProjected[0].y+=200;
                      view.goTo({ target: symbolProjected, zoom: 14 });
                    }, 250)                    
                  });

                  view.map.addMany([symbolLayer]);                  
                });
              });                
          });
      })
}