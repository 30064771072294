import React, {useEffect, useState} from "react";
import { RouteComponentProps } from "react-router-dom";
import { getNumVisits, getCompletedExpeditions, getMostActiveAgents, getNumCorrectAnswers, getUniqueExpeditions, getMostVisitedExpeditions } from "../core/services/statservice";
import { useContext } from "react";
import { AppContext } from "../core/state/context";

export const StatsPage = ({ match }: RouteComponentProps<any>) => {
  const context = useContext(AppContext);

  const [numVisits, setNumVisits] = useState(null);
  const [numCompleted, setNumCompleted] = useState(null);
  const [numCorrectAnswers, setNumCorrectAnswers] = useState(null);
  const [numUniqueExpeditions, setNumUniqueExpeditions] = useState(null);
  const [mostVisitedExpeditions, setMostVisitedExpeditions] = useState(null);
  const [mostActiveAgents, setMostActiveAgents] = useState(null);

  useEffect(() => {
    if (context.token.value){
      getNumVisits(context.token.value).then((result) => {
        setNumVisits(result);
      });

      getCompletedExpeditions(context.token.value).then((result) => {
        setNumCompleted(result);
      });

      getNumCorrectAnswers(context.token.value).then((result) => {
        setNumCorrectAnswers(result);
      });

      getUniqueExpeditions(context.token.value).then((result) => {
        setNumUniqueExpeditions(result);
      });

      getMostVisitedExpeditions(context.token.value).then((result) => {
        setMostVisitedExpeditions(result);
      });

      getMostActiveAgents(context.token.value).then((result) => {
        setMostActiveAgents(result);
      });
    }
  }, context.token.value);

  return (
    <div className="w-full h-screen overflow-scroll flex justify-center">
      <div className="flex flex-col">
        <div className="p-8 max-w-2xl">
          <h1 className="text-white text-3xl font-bold pb-5">Toppliste</h1>
          {mostVisitedExpeditions &&
              <div className="text-white w-80 app-container-bg text-center p-5 m-5">
                <div className="pb-3">
                  Mest besøkte ekspedisjoner
                </div>
                <ol className="px-2 stat-list text-sm text-left">
                {mostVisitedExpeditions.map((exp: any, index: number) => {
                  if (exp.attributes.Tur_Navn === null) {
                    return null;
                  }

                  return <li className="p-1" key={index}>
                    {exp.attributes.Tur_Navn}
                  </li> 
                })}
                </ol>
              </div> 
          }
           {mostActiveAgents &&
              <div className="text-white w-80 app-container-bg text-center p-5 m-5">
                <div className="pb-3">
                  Toppagenter
                </div>
                <ol className="px-2 stat-list text-sm text-left">
                {mostActiveAgents.map((exp: any, index: number) => {
                  if (exp.attributes.Agentkode === null || exp.attributes.Agentkode === "0") {
                    return null;
                  }

                  return <li className="p-1" key={index}>
                    {exp.attributes.Agentkode } <p className="float-right">{exp.attributes.sumPoints} poeng</p>
                  </li> 
                })}
                </ol>
              </div> 
          }
          {numCompleted &&
            <div className="text-white w-50 app-container-bg text-center p-5 m-5">
              <div className="text-3xl">{numCompleted}</div>
              <div className="">
              Hvor mange har fullført en ekspedisjon
              </div>
            </div> 
          }
          {numVisits && (
            <div className="text-white w-50 app-container-bg text-center p-5 m-5">
              <div className="text-3xl">{numVisits}</div>
              <div className="">
                Besøkte turstopp
              </div>
            </div> 
          )}
          {numVisits && numCorrectAnswers && 
            <div className="text-white w-80 app-container-bg text-center p-5 m-5">
              <div className="text-3xl">{Math.floor((numCorrectAnswers / numVisits) * 100)} %</div>
              <div className="">
                Riktig besvarte spørsmål
              </div>
            </div> 
          }
          {numUniqueExpeditions &&
              <div className="text-white w-80 app-container-bg text-center p-5 m-5">
                <div className="text-3xl">{numUniqueExpeditions}</div>
                <div className="">
                  Ekspedisjoner som er testet
                </div>
              </div> 
          }
          </div>
      </div>
    </div>
  );
};
