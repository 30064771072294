import axios from "axios";
import qs from 'querystring';
import { appSettings } from "../settings";

export const queryPortalItem = (query: any): Promise<any> => {   
    let params = query;

    const config = {
        headers: {
          'Content-Type': 'application/json'
        }
    }

    return new Promise(resolve => {
        axios.get(`${appSettings.serviceBaseUrl}/${appSettings.agentsServicePath}/query?${qs.stringify(params)}`,              
            config).then((results) => {
            resolve(results);
        })
    })
}